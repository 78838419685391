import React from "react";

const DashFooter = () => (
  <>
    <footer className="main-footer font-small black pt-3 mt-5">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12" align="center">
          <span style={{ fontSize: "12px", color: "silver" }}>
            Copyright © 2020-24 By CookandChef India Pvt. Ltd. All Rights
            Reserved.
          </span>
        </div>
      </div>
    </footer>
  </>
);

export default DashFooter;
