import React, { useState } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import DataSkeleton from "../../auth_screens/dataskeleton";
import { getemployerPlanHistory } from "../../../store/slices/employerprofile";
import { useDispatch } from "react-redux";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import axios from "axios";
import Modal2 from "../../auth_screens/Modal2";
import { useEffect } from "react";
import { EmployerPlanInitiatePayment } from "../../../store/slices/employerplan";
import { getPaymentsKey } from "../../../store/slices/config";
import success from "../../../Assets/Images/check.png";

function PlanHistory() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [totaldata, setTotaldata] = useState();
  const [data, setData] = useState([]);
  const [pagData, setPageData] = useState();
  const [dataCount, setdataCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [sortVal, setSortVal] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [key, setKey] = useState();
  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  React.useEffect(() => {
    dispatch(getemployerPlanHistory())
      .unwrap()
      .then((data) => {
        setData(data?.empPlanHistory?.data);
        setdataCount(data?.empPlanHistory?.dataCount);
        setTotaldata(data?.empPlanHistory?.dataCount);
        setFilteredData(data?.empPlanHistory?.data);
        setPageData(data?.empPlanHistory?.data.slice(0, itemsPerPage));
      })
      .catch(({ message }) => {
        setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  useEffect(() => {
    handleApplyFilter();
  }, [sortVal]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPageData(filteredData.slice(start, end));
  }, [filteredData, currentPage, itemsPerPage]);

  const handleApplyFilter = () => {
    let sortedArray = [...data];
    if (sortVal === "status-all") {
      let sortdata = sortedArray.filter(
        (elem) => elem?.status === 1 || elem?.status === 0
      );
      setFilteredData(sortdata);
    } else if (sortVal === "status-active") {
      let sortdata = sortedArray.filter((elem) => elem?.status === 1);
      setFilteredData(sortdata);
    } else if (sortVal === "status-expired") {
      let sortdata = sortedArray.filter((elem) => elem?.status === 0);
      setFilteredData(sortdata);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setSortVal(value);
    setCurrentPage(1);
    search.set("sortVal", e.target.value);
    search.set("page", 1);
    setSearch(search, {
      replace: true,
    });
    handleApplyFilter();
    setCurrentPage(1);
  };

  const PurchasePlan = (elem) => {
    const item = {
      employerPointsId: elem.id,
      discount: 0,
      address: elem.planTransactionId.address,
    };
    dispatch(EmployerPlanInitiatePayment(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        if (data.user.data.transactionNo) {
          initiateRazor(data.user.data);
        }
      })
      .catch(({ message }) => {
        setModalMessage(message);
        // setShowModal(true);
      });
  };

  const initiateRazor = async (data) => {
    try {
      const options = {
        key,
        amount: data.price,
        currency: "INR",
        name: data?.planName,
        description: "employer plan",
        image: "",
        order_id: data.transactionNo,
        adress: data.address,
        handler: async function (response) {
          const data22 = {
            transactionNo: data.transactionNo,
            transactionPaymentNo: response.razorpay_payment_id,
            transactionSignature: response.razorpay_signature,
          };

          const result = await axios.put(
            "/employer/employerplan/supportAssistance/payment/confirm",
            data22,
            { headers: { "x-access-token": token } }
          );
          setModalMessage(result.data.message);

          setTimeout(() => {
            setsuccessModal(true);
          }, [100]);
        },

        prefill: {
          name: user.data.fullName,
          email: user.data.email,
          contact: user.data.mobile,
        },

        theme: {
          color: "#121212",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handleCloseModal = () => {
    navigate("/employer/dashboard");
  };

  useEffect(() => {
    document.title = "CookandChef | Plan History";
  }, []);

  useEffect(() => {
    // Dynamically load Razorpay script
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(getPaymentsKey())
      .unwrap()
      .then((data) => {
        setKey(data.user.data.key);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, []);

  const calculateDateDifference = (startDate, endDate) => {
    const startDateMoment = Moment(startDate, "YYYY-MM-DD");
    const endDateMoment = Moment(endDate, "YYYY-MM-DD");

    if (startDateMoment.isValid() && endDateMoment.isValid()) {
      return endDateMoment.diff(startDateMoment, "days");
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/employer/dashboard" className="bcrumb">
            Home
          </Link>
          &nbsp;&bull; Plan History
        </div>

        {dataCount === 0 && (
          <Row className="card mt-4 p-5">
            <Col lg={12} xs={12} md={12} align="center">
              <h3>No plans available</h3>
              <br />
              <button
                onClick={() => navigate("/hin/employer/plans")}
                className="saveBtn"
              >
                Buy Plan
              </button>
            </Col>
          </Row>
        )}
        {dataCount !== 0 && (
          <div>
            <Form>
              <Row>
                <Col lg={12} sm={12} xs={12} md={12}>
                  <Row className="justify-content-end">
                    <Col lg={2} sm={4} md={3} xs={3} align="right">
                      <label
                        style={{
                          height: "40px",
                          lineHeight: "40px",

                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Filter by:
                      </label>
                    </Col>
                    <Col lg={3} sm={7} md={4} xs={7}>
                      <Form.Group className="mb-1">
                        <Form.Control
                          as="select"
                          style={{
                            width: "180px",
                          }}
                          name="experience"
                          value={sortVal}
                          onChange={handleChange}
                        >
                          <option value="status-all">All</option>
                          <option value="status-active">Active</option>
                          <option value="status-expired">Expired</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <div className="mt-4 card table-responsive">
              {isLoading ? (
                <Row className="card p-2 table-responsive">
                  {DataSkeleton()}
                </Row>
              ) : (
                <Row className="card p-2 table-responsive">
                  <Table responsive striped hover>
                    <thead>
                      <tr>
                        <th className="text-left ps-3">Plan Name</th>
                        <th className="text-center">Plan Price</th>
                        <th className="text-center">Date of Purchase</th>
                        <th className="text-center">Expiry</th>
                        <th className="text-center">Validity</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagData?.map((transactionVal, index) => (
                        <>
                          <tr>
                            <td className="text-left ps-3">
                              <Link
                                onClick={(event) =>
                                  handleEpandRow(event, transactionVal.id)
                                }
                              >
                                {transactionVal?.planTransactionId?.planName}
                              </Link>
                              <br />
                              {transactionVal?.supportAssistance === 0 &&
                                transactionVal?.status === 1 && (
                                  <Link
                                    style={{ color: "#ff5c09" }}
                                    onClick={() => PurchasePlan(transactionVal)}
                                  >
                                    <u>Add Assistance</u>
                                  </Link>
                                )}
                            </td>
                            <td className="text-center">
                              {transactionVal?.planTransactionId?.price}
                            </td>

                            <td className="text-center">
                              {Moment.utc(
                                transactionVal?.planTransactionId
                                  ?.transactionStartDateTime
                              ).format("DD-MM-YYYY")}
                            </td>

                            <td className="text-center">
                              {Moment.utc(
                                transactionVal?.planTransactionId
                                  ?.transactionEndDateTime
                              ).format("DD-MM-YYYY")}
                            </td>

                            <td className="text-center">
                              {calculateDateDifference(
                                transactionVal.planTransactionId
                                  .transactionStartDateTime,
                                transactionVal.planTransactionId
                                  .transactionEndDateTime
                              )}
                              {calculateDateDifference(
                                transactionVal.planTransactionId
                                  .transactionStartDateTime,
                                transactionVal.planTransactionId
                                  .transactionEndDateTime
                              ) === 1
                                ? " Day"
                                : " Days"}
                            </td>
                            <td className="text-center">
                              {transactionVal?.status === 1 && (
                                <span
                                  style={{
                                    backgroundColor: "rgb(201, 240, 175)",
                                    borderRadius: "5px",
                                    padding: "4px 12px",
                                  }}
                                >
                                  Active
                                </span>
                              )}
                              {transactionVal?.status === 0 &&
                                transactionVal?.planType !==
                                  "refundrequested" && (
                                  <span
                                    style={{
                                      backgroundColor: "#e9ebf0",
                                      borderRadius: "5px",
                                      padding: "4px 10px",
                                    }}
                                  >
                                    Expired
                                  </span>
                                )}
                              {transactionVal?.status === 0 &&
                                transactionVal?.planType ===
                                  "refundrequested" && (
                                  <span
                                    style={{
                                      backgroundColor: "#e9ebf0",
                                      borderRadius: "5px",
                                      padding: "4px 10px",
                                    }}
                                  >
                                    Cancelled
                                  </span>
                                )}
                            </td>
                            <td className="text-center">
                              {transactionVal?.planTransactionId
                                ?.invoiceUrl && (
                                <Link
                                  to={
                                    transactionVal?.planTransactionId
                                      ?.invoiceUrl
                                  }
                                  className="downloadBtn"
                                >
                                  Download
                                </Link>
                              )}
                            </td>
                          </tr>
                          <>
                            {expandedRows.includes(transactionVal?.id) ? (
                              <tr
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "10px",
                                  borderTop: "none",
                                }}
                              >
                                <td className="text-left">
                                  No. of Job Post
                                  <br />
                                  {transactionVal?.currentJobPoints}
                                  &nbsp; / {transactionVal?.totalJobPoints}
                                </td>

                                <td className="text-center">
                                  No. of Profile View
                                  <br />
                                  {transactionVal?.currentProfileViewPoints}
                                  &nbsp; /
                                  {transactionVal?.totalProfileViewPoints}
                                </td>
                                <td className="text-center">
                                  <br />
                                  <span>Support Assistance</span>
                                  <br />
                                  {transactionVal.supportAssistance === 1 ? (
                                    <p>Yes</p>
                                  ) : (
                                    <p>No</p>
                                  )}
                                </td>
                                <td className="text-center">
                                  <span>No. of Response</span>
                                  <br />
                                  {transactionVal?.currentResponsePoints}
                                </td>

                                <td className="text-center">
                                  <br />
                                  <span>Refund Policy</span>
                                  <br />
                                  {(transactionVal?.planTransactionId
                                    ?.paymentDetails?.refundPolicy === 1 ||
                                    transactionVal?.planTransactionId
                                      ?.refundPolicy === 1) && <p>Yes</p>}
                                  {(transactionVal?.planTransactionId
                                    ?.paymentDetails?.refundPolicy === 0 ||
                                    transactionVal?.planTransactionId
                                      ?.refundPolicy === 0) && <p>No</p>}
                                </td>
                                <td className="text-center">
                                  Discount
                                  <br />
                                  {transactionVal?.planTransactionId?.discount}
                                </td>
                                <td className="text-center"></td>
                              </tr>
                            ) : null}
                          </>
                        </>
                      ))}
                    </tbody>
                  </Table>
                  {filteredData?.length / itemsPerPage > 1 && (
                    <div
                      className="justify-content-right align-right"
                      align="right"
                    >
                      <ReactPaginate
                        key={currentPage}
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakLinkClassName={"page-link"}
                        pageCount={Math.ceil(
                          filteredData?.length / itemsPerPage
                        )}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={pagesToShowInitially}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        disabledClassName="disabled"
                        initialPage={currentPage - 1}
                        renderLastPage={() => null}
                      />
                    </div>
                  )}
                </Row>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PlanHistory;
