import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import MultiRangeSlider from "../../../../utils/helpers/MultirangeSlider";
import { BsBookmark, BsBriefcase } from "react-icons/bs";
import { MdOutlineFoodBank } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiFilter } from "react-icons/fi";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { getCuisines } from "../../../../store/slices/config";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { MdCurrencyRupee } from "react-icons/md";
import {
  findJobs,
  removeshortlistjob,
  shortlistjob,
} from "../../../../store/slices/cookprofile";
import dog from "../../../../Assets/Images/no-result.jpg";
import restaurant from "../../../../Assets/Images/restaurant.png";
import { Link } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import newtag from "../../../../Assets/Images/new.png";
import { FaBookmark } from "react-icons/fa";
import Modal2 from "../../auth_screens/Modal2";
import Skeleton from "react-loading-skeleton";
import Moment from "moment";

function FindJobs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | नौकरी ढूंढे";
  }, []);

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [totaldata, setTotaldata] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const handleapply = () => setShow(false);
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isRespTrue, setIsRespTrue] = useState(false);
  const [pagechange, setPageChange] = useState(false);
  const myData = useSelector((state) => state.cookData.jobsdata);
  const itemsPerPage = 6;
  const pagesToShowInitially = 3;
  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setPageChange(true);
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  const removeshortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: false };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      jobId: item._id,
    };
    dispatch(removeshortlistjob(elem))
      .then((data) => {})
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  const shortList = (item, index) => {
    const newData = [...data];
    const updatedItem = { ...newData[index], isShortlisted: true };
    newData[index] = updatedItem;
    setData(newData);
    var elem = {
      id: item._id,
    };

    dispatch(shortlistjob(elem))
      .then((data) => {})
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setisLoading(true);
    }, 300);
    setisLoading(false);
    console.log(myData, data);
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minexpval = search.get("minimumexp");
    const maxexpval = search.get("maximumexp");
    const minsal = search.get("minimumSalary");
    const maxsal = search.get("maximumSalary");
    const locationq = search.get("location");
    const pageq = search.get("page");
    if (!cuisineval && !minexpval && !minsal && !locationq) {
      getjobs();
    } else if (pagechange === true) {
      getjobs();
    }
  }, [currentPage]);

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minexpval = search.get("minimumexp");
    const maxexpval = search.get("maximumexp");
    const minsal = search.get("minimumSalary");
    const maxsal = search.get("maximumSalary");
    const locationq = search.get("location");
    const pageq = search.get("page");
    if (!cuisineval && !minexpval && !minsal && !locationq) {
      getjobs();
    } else {
      setData(myData?.jobsdata?.data);
      setTotaldata(myData?.jobsdata?.totalDataCount);
    }
  }, [myData]);

  const getjobs = () => {
    setisLoading(false);
    dispatch(findJobs(item))
      .unwrap()
      .then((data) => {
        setData(data?.jobsdata?.data);
        setTotaldata(data?.jobsdata?.totalDataCount);
        setIsRespTrue(true);
      })
      .catch(({ message }) => {})
      .finally(() => {
        setisLoading(true);
      });
  };

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={120} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/cook/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull;&nbsp;नौकरी ढूंढो
        </div>
        <Form className="mt-2">
          <div align="right">
            <Button
              className="filter_btn d-flex mb-2 justify-content-around align-items-center d-lg-none d-md-block"
              onClick={handleShow}
              style={{ border: "solid 1px #ff5c09", padding: "6px 10px" }}
            >
              Filter <FiFilter />
            </Button>
          </div>
        </Form>
        <BottomSheet
          open={show}
          onDismiss={() => setShow(false)}
          blocking={false}
          snapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.9]}
        >
          <Filter
            setItem={setItem}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handleapply={handleapply}
            setisLoading={setisLoading}
          />
        </BottomSheet>
        <Row className="mt-4">
          {data?.length === 0 ? (
            <Col lg={8} md={12} sm={12} xs={12}>
              <Row
                className="d-flex justify-items-center align-items-center"
                style={{
                  backgroundColor: "white",
                  // height: "20dvh",
                  // width: "100%",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <Col lg={12} align="center">
                  <p style={{ fontSize: "18px" }}>
                    <b>No results</b> found for your search!
                  </p>
                  <br />
                  <img src={dog} alt="" style={{ height: "250px" }} />
                  <br />
                  <br />
                  <p style={{ fontSize: "18px" }}>
                    Refine your search by using different keywords or checking
                    for spelling mistakes.
                  </p>
                </Col>
              </Row>
            </Col>
          ) : isLoading ? (
            <Col lg={8} md={12} sm={12} xs={12}>
              {data?.map((item, index) => (
                <Row
                  className="d-flex justify-items-center align-items-center"
                  key={index}
                  style={{
                    backgroundColor: "white",
                    // height: "20dvh",
                    // width: "100%",
                    borderRadius: "15px",
                    padding: "8px",
                    marginTop: "8px",
                  }}
                >
                  <Col lg={2} md={2} xs={3} className="justify-content-center">
                    {item?.isViewed === false && (
                      <img src={newtag} alt="" className="newTagJob" />
                    )}
                    {item?.employerId?.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={item?.employerId?.dp}
                        alt="user profile"
                      />
                    )}
                    {!item.employerId.dp && (
                      <img
                        className="align-self-center"
                        style={{
                          borderRadius: "10px",
                          height: "75px",
                          width: "75px",
                        }}
                        src={restaurant}
                        alt="user profile"
                      />
                    )}
                  </Col>
                  <Col lg={9} xs={7} md={8} className="ps-3">
                    <h6
                      style={{ fontWeight: "600", marginBottom: "0.2rem" }}
                      className="ms-3"
                    >
                      {item?.designation.substring(0, 40)}
                    </h6>
                    <p
                      style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                      className="ms-3"
                    >
                      {item?.employerId?.fullName.substring(0, 50)}
                    </p>

                    {item?.cuisines?.length >= 3 ? (
                      <p
                        style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cuisines?.join(", ").substring(0, 20)}...
                      </p>
                    ) : (
                      <p
                        style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                        className="ms-3"
                      >
                        <MdOutlineFoodBank style={{ marginBottom: "2px" }} />
                        &nbsp;
                        {item?.cuisines?.join(", ")}
                      </p>
                    )}
                    <p
                      style={{ marginBottom: "0.25rem", fontSize: "14px" }}
                      className="ms-3"
                    >
                      <MdCurrencyRupee style={{ marginBottom: "2px" }} />
                      &nbsp;{item?.salary}&nbsp;{" "}
                      <GrLocation style={{ marginBottom: "2px" }} />
                      &nbsp;{item?.location}
                    </p>
                  </Col>
                  <Col
                    lg={1}
                    xs={2}
                    md={2}
                    align="right"
                    style={{ marginTop: "-80px" }}
                  >
                    {item?.isShortlisted === true && (
                      <div
                        onClick={() => removeshortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <FaBookmark />
                      </div>
                    )}

                    {item?.isShortlisted === false && (
                      <div
                        onClick={() => shortList(item, index)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        <BsBookmark />
                      </div>
                    )}
                  </Col>
                  <Col lg={3} xs={6}>
                    <p
                      className="text-left my-1"
                      style={{ fontSize: "1.5dvh" }}
                    >
                      Posted on &nbsp;
                      {Moment.utc(item?.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </Col>
                  <Col lg={9} xs={6}>
                    <div align="right">
                      &nbsp;
                      <Link
                        to={`/hin/cook/job-details/${item._id}`}
                        style={{ color: "#000" }}
                      >
                        <Button className="view_profile ms-5">इसे देखें</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          ) : (
            <Col lg={8} xs={12} md={12} align="center">
              {ShowSkeleton()}
            </Col>
          )}
          <Col lg={4} md={4} xs={12} className="filter_parent">
            <div
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "white",
                borderRadius: "15px",
                paddingBottom: "100px",
              }}
            >
              <Filter
                setItem={setItem}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                handleapply={handleapply}
                setisLoading={setisLoading}
              />
            </div>
          </Col>
        </Row>
        {totaldata / itemsPerPage > 1 && (
          <div className="justify-content-right align-right">
            <ReactPaginate
              key={currentPage}
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakLinkClassName={"page-link"}
              pageCount={Math.ceil(totaldata / itemsPerPage)}
              marginPagesDisplayed={0}
              pageRangeDisplayed={pagesToShowInitially}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              initialPage={currentPage - 1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default FindJobs;

const Filter = ({
  setItem,
  setCurrentPage,
  currentPage,
  handleapply,
  setisLoading,
}) => {
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [location, setLocation] = useState("");
  const [maximumExperience, setmaximumExperience] = useState("");
  const [minimumExperience, setminimumExperience] = useState("");
  const [minimumSalary, setminimumSalary] = useState("5000");
  const [maximumSalary, setmaximumSalary] = useState("25000");
  const [exp, setExp] = useState("");
  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useSearchParams();
  const [min, setMin] = useState(5000);
  const [max, setMax] = useState(100000);
  const [minVal, setMinVal] = useState(5000);
  const [maxVal, setMaxVal] = useState(100000);
  const [cuisineInputval, setCuisienInputVal] = useState("");
  const [locationError, setLocationError] = useState("");
  const [cuisineError, setCuisineError] = useState();
  const [sortby, setSortBy] = useState();

  const dispatch = useDispatch();

  const handleInput = (e) => {
    setminimumSalary(e.minValue);
    setmaximumSalary(e.maxValue);
  };
  const handleApplyFilter = () => {
    if (minimumExperience) {
      search.set("minimumexp", minimumExperience);
    }
    if (maximumExperience) {
      search.set("maximumexp", maximumExperience);
    }
    if (minimumSalary) {
      search.set("minimumSalary", minimumSalary);
    }
    if (maximumSalary) {
      search.set("maximumSalary", maximumSalary);
    }
    search.set("page", 1);
    if (chefCuisines.length) {
      search.set("cuisines", chefCuisines.join(","));
    }

    setSearch(search, {
      replace: true,
    });
    const item = {
      cuisines:
        chefCuisines[0] !== "" && chefCuisines.length
          ? chefCuisines
          : undefined,
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      minimumExperience:
        parseInt(minimumExperience) >= 0
          ? parseInt(minimumExperience)
          : undefined,
      maximumExperience: parseInt(maximumExperience) || undefined,
      minimumSalary: parseInt(minimumSalary) || undefined,
      maximumSalary: parseInt(maximumSalary) || undefined,
      sortingBySalary:
        sortby === "salary-high-low"
          ? -1
          : sortby === "salary-low-high"
          ? 1
          : undefined,
      sortingByCreatedAt:
        sortby === "date-desc-asc"
          ? -1
          : sortby === "date-asc-desc"
          ? 1
          : undefined,
      limit: 6,
      page: 1,
    };

    if (cuisineInputval && !chefCuisines.length) {
      setCuisineError("कृपया चयन कीजिए valid cuisine");
    } else if (locationError) {
    } else {
      setItem(item);
      setCurrentPage(1);
      dispatch(findJobs(item))
        .unwrap()
        .then((data) => {
          setTimeout(() => {
            setisLoading(true);
          }, [300]);
          setisLoading(false);
        })

        .catch(({ message }) => {});
      handleapply();
    }
  };

  const handleResetFilter = () => {
    setchefCuisines([]);
    setmaximumExperience("");
    setminimumExperience("");
    setMinVal(5000);
    setMaxVal(100000);
    setminimumSalary(5000);
    setmaximumSalary(100000);
    setSortBy("");
    setSearch([]);
    localStorage.removeItem("coordinates");
    setLocation("");
    setExp("");
    setCoordinates([]);
    const item = {
      limit: 6,
      page: 1,
    };
    setItem(item);
    handleapply();
    // dispatch(findJobs(item))
    //   .unwrap()
    //   .then((data) => {})
    //   .catch(({ message }) => {});
  };

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minexpval = search.get("minimumexp");
    const maxexpval = search.get("maximumexp");
    const minsal = search.get("minimumSalary");
    const maxsal = search.get("maximumSalary");
    const locationq = search.get("location");
    const pageq = search.get("page");

    if (cuisineval !== null && cuisineval[0] !== "") {
      setchefCuisines(cuisineval);
    }

    if (minexpval == 0) {
      setminimumExperience(0);
      setmaximumExperience(2);
      setExp("0-2");
    } else if (minexpval == 2) {
      setminimumExperience(2);
      setmaximumExperience(5);
      setExp("2-5");
    } else if (minexpval == 5) {
      setminimumExperience(5);
      setmaximumExperience(8);
      setExp("5-8");
    } else if (minexpval == 8) {
      setminimumExperience(8);
      setmaximumExperience(10);
      setExp("8-10");
    } else if (minexpval == 10) {
      setminimumExperience(10);
      setmaximumExperience(15);
      setExp("10-15");
    } else if (minexpval == 15) {
      setminimumExperience(15);
      setmaximumExperience(25);
      setExp("15-25");
    } else if (minexpval == 25) {
      setminimumExperience(25);
      setmaximumExperience(50);
      setExp("25-50");
    }
    if (sortby !== null) {
      setSortBy(sortby);
    }
    if (minsal !== null) {
      setMinVal(parseInt(minsal));
      setminimumSalary(parseInt(minsal));
    }
    if (maxsal !== null) {
      setMaxVal(parseInt(maxsal));
      setmaximumSalary(parseInt(maxsal));
    }
    let coordString;
    let coordArray;
    if (locationq !== null) {
      setLocation(locationq);
      coordString = localStorage.getItem("coordinates");
      coordArray = coordString ? coordString.split(",") : [];
      setCoordinates(coordArray);
    }

    if (cuisineval || minexpval || minsal || locationq || pageq) {
      const item = {
        cuisines:
          cuisineval[0] !== "" && cuisineval.length ? cuisineval : undefined,
        latitude: coordArray ? coordArray[0] : undefined,
        longitude: coordArray ? coordArray[1] : undefined,
        minimumExperience:
          parseInt(minexpval) >= 0 ? parseInt(minexpval) : undefined,
        maximumExperience: parseInt(maxexpval) || undefined,
        minimumSalary: parseInt(minsal) || undefined,
        maximumSalary: parseInt(maxsal) || undefined,
        sortingBySalary:
          sortby === "salary-high-low"
            ? -1
            : sortby === "salary-low-high"
            ? 1
            : undefined,
        sortingByCreatedAt:
          sortby === "date-desc-asc"
            ? -1
            : sortby === "date-asc-desc"
            ? 1
            : undefined,
        limit: 6,
        page: parseInt(pageq) || 1,
      };

      setItem(item);

      dispatch(findJobs(item))
        .unwrap()
        .then((data) => {})
        .catch(({ message }) => {});
    }
  }, []);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);

  const options1 = cuisines.map((cuisine) => ({
    value: cuisine._id, // You can change this to another unique identifier if needed
    label: cuisine.cuisineName,
    // You can add other properties like color, isFixed, disabled based on your requirements
  }));

  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const inputChangeCuisine = (e) => {
    setCuisienInputVal(e);
    setCuisineError("");
  };

  const handleChefCuisineChange = (selected) => {
    setCuisineError("");
    setCuisienInputVal("");

    search.set("cuisines", selected.join(","));
    setSearch(search, {
      replace: true,
    });
    const validationErrors = { ...errors };
    setchefCuisines(selected);

    validationErrors.chefCuisines =
      selected.length === 0 ? "कृपया ज्ञात व्यंजन दर्ज करें" : "";
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setLocationError("कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें");
        } else {
          setLocationError("");
          setLocation(place.name);
          const coordinates = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          localStorage.setItem("coordinates", coordinates);
          setCoordinates(coordinates);
          const existingSearchParams = new URLSearchParams(search);

          // Set the new location parameter
          existingSearchParams.set("location", place.name);

          // Set the merged parameters in the search
          setSearch(existingSearchParams, {
            replace: true,
          });
        }
      });

      inputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = inputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setLocationError("कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें");
        }
      });
    }
  }, []);

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };

  const handleExpChange = (e) => {
    const [minExp, maxExp] = e.target.value?.split("-");
    setExp(e.target.value);
    // Set the minimum and maximum experience in the state
    setminimumExperience(minExp);
    setmaximumExperience(maxExp);
    search.set("minimumexp", minExp);
    search.set("maximumexp", maxExp);

    setSearch(search, {
      replace: true,
    });
  };

  const HandleChange = ({ max, min }) => {
    setminimumSalary(min);
    setmaximumSalary(max);
  };

  const handleChangeSort = (e) => {
    search.set("sortby", e.target.value);
    setSearch(search, {
      replace: true,
    });
    setSortBy(e.target.value);
  };

  return (
    <>
      <div className="mx-3">
        <Form>
          <Form.Group className="mb-4 mt-2">
            <Form.Label className="mt-3">भोजन</Form.Label>

            <Typeahead
              clearButton
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              className="input1"
              onChange={handleChefCuisineChange}
              options={options}
              placeholder="Cuisine"
              selected={chefCuisines}
              isInvalid={!!cuisineError}
              onInputChange={inputChangeCuisine}
            />
            <Form.Control.Feedback type="invalid">
              {cuisineError}
            </Form.Control.Feedback>
            {/*   <MultiSelect 
     clearButton
     id="basic-typeahead-multiple"
     labelKey="name"
     multiple

     onChange={handleChefCuisineChange}
     options={options1}
     placeholder="ज्ञात व्यंजन"
     value={chefCuisines}
   
/> */}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>जगह</Form.Label>
            <Form.Control
              name="location"
              placeholder="Enter location"
              value={location}
              ref={inputRef}
              onChange={handlelocationChange}
              isInvalid={!!locationError}
            />
            <Form.Control.Feedback type="invalid">
              {locationError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>सैलरी</Form.Label>
            {/* <RangeSlider
            name="salary"
            value={minimumSalary}
            onChange={salaryChange}
          /> */}
            <MultiRangeSlider
              min={min}
              max={max}
              minVal={minVal}
              maxVal={maxVal}
              setMinVal={setMinVal}
              setMaxVal={setMaxVal}
              onChange={({ min, max }) => HandleChange({ min, max })}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label className="mt-3">अनुभव</Form.Label>
            <Form.Control
              as="select"
              name="experience"
              value={exp}
              className="input1"
              onChange={handleExpChange}
            >
              <option value="" selected disabled hidden>
                Select
              </option>
              <option value="0-2">0-2 Years</option>
              <option value="2-5">2-5 Years</option>
              <option value="5-8">5-8 Years</option>
              <option value="8-10">8-10 Years</option>
              <option value="10-15">10-15 Years</option>
              <option value="15-25">15-25 Years</option>
              <option value="26-50">More than 25 Years</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label className="mt-3">Sort By</Form.Label>
            <Form.Control
              as="select"
              name="sortby"
              value={sortby}
              onChange={handleChangeSort}
            >
              <option value="" selected disabled hidden>
                Select
              </option>
              <option value="salary-low-high">Salary Low to High</option>
              <option value="salary-high-low">Salary High to Low</option>
              <option value="date-asc-desc">Posted Date Asc to Desc</option>
              <option value="date-desc-asc">Posted Date Desc to Asc</option>
            </Form.Control>
          </Form.Group>
          <div className="group d-flex justify-content-around align-items-center mt-5">
            <div className="reset" onClick={handleResetFilter}>
              <Link>
                <u>रीसेट</u>
              </Link>
            </div>

            <Button onClick={handleApplyFilter} className="apply_btn ms-2">
              जमा करना
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
