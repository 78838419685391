import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Image, Offcanvas } from "react-bootstrap";
import { CgMenuLeftAlt } from "react-icons/cg";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { BsCardChecklist } from "react-icons/bs";
import logo from "../../Assets/Images/nav_logo.png";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { TbCoinRupee } from "react-icons/tb";
import { TbChefHat } from "react-icons/tb";
import { SlBriefcase } from "react-icons/sl";
import { IoSettingsOutline } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { API_PATHS } from "../../utils/constants/api.constants";
import EnableProfileModal from "../auth_screens/EnableProfileModal";

export default function EmployerMobNavbar() {
  const navigate = useNavigate();
  const [enableProfile, setEnableProfile] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const data1 = JSON.parse(localStorage.getItem("user"));
  const weavyId = localStorage.getItem("weavyId");
  const [weavyToken, setWeavyToken] = useState();
  const messagesNo = useSelector(
    (state) => state.configData.messageNotifications
  );

  useEffect(() => {
    setData(data1?.data);
  }, []);

  const fetchData = async () => {
    try {
      const token = await fetchAuthToken();
      setWeavyToken(token);
    } catch (error) {
      console.error("Error fetching authentication token:", error);
    }
  };

  useEffect(() => {
    if (weavyId) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 50 * 60 * 1000); // 50 minutes

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [weavyId]);

  const setWeavyTokenInLocalStorage = (token, expirationTime) => {
    localStorage.setItem("WeavyToken", token);
    localStorage.setItem("WeavyTokenExpiry", expirationTime.toString());
  };

  const fetchAuthToken = async () => {
    try {
      const storedToken = localStorage.getItem("WeavyToken");
      const storedTokenExpiry = localStorage.getItem("WeavyTokenExpiry");

      if (storedToken && storedTokenExpiry) {
        const currentTime = new Date().getTime();
        if (currentTime < parseInt(storedTokenExpiry, 10)) {
          return storedToken;
        }
      }
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/users/${data1?.data?.id}/tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${API_PATHS.weavyKey}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.access_token;
        localStorage.setItem("WeavyToken", token);
        if (token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          setWeavyTokenInLocalStorage(token, expirationTime);

          return token;
        } else {
          throw new Error("Authentication token not received.");
        }
      } else {
        throw new Error(
          `Error fetching authentication token: ${response.status} - ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      throw error;
    }
  };

  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);
  const isActive = (path) => window.location.pathname === path;

  const gotoPostJob = () => {
    if (data.status === 2) {
      setEnableProfile(true);
    } else {
      navigate("/employer/post-job");
    }
  };

  return (
    <>
      <EnableProfileModal
        showModal={enableProfile}
        setShowModal={setEnableProfile}
      />
      <CgMenuLeftAlt
        style={{ fontSize: "30px", margin: "14px" }}
        variant="primary"
        onClick={handleShow}
        className="sticky-top d-sm-block d-lg-none"
      />

      <Offcanvas show={show} onHide={handleClose} backdrop={false}>
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <SidebarMenu className="d-flex justify-content-center flex-column align-items-center">
            <SidebarMenu.Brand>
              <Image
                className="text-center img-fluid"
                src={logo}
                style={{ height: "70px" }}
              />
            </SidebarMenu.Brand>

            <SidebarMenu.Collapse className="mt-3">
              <SidebarMenu.Body onClick={() => setShow(!show)}>
                <SidebarMenu.Nav
                  className="mb-4"
                  onClick={() => navigate("/employer/dashboard")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/dashboard") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoHomeOutline className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Dashboard</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => navigate("/employer/profile")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/profile") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <FaRegUser className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Profile</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => navigate("/employer/find-chef")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/find-chef") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbChefHat className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Find Chef</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => navigate("/employer/plans")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/plans") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbCoinRupee className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Buy Plans</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => navigate("/employer/plan-history")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/plan-history") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <TbCoinRupee className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>My Plans</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => gotoPostJob()}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/post-job") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <SlBriefcase className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Post Job</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => navigate("/employer/manage-jobs")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/manage-jobs") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <SlBriefcase className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Manage Job</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => navigate("/employer/applications")}
                >
                  <SidebarMenu.Nav.Link>
                    <SidebarMenu.Nav.Icon className="px-2">
                      <BsCardChecklist />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title
                      className={`${
                        isActive("/employer/applications") ? "active-link" : ""
                      }`}
                    >
                      Applications
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-4 menu_body_menu"
                  onClick={() => navigate("/employer/settings")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/settings") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoSettingsOutline className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Settings</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/employer/shared-profiles")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/shared-profiles") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <MdSupportAgent className="navIcon" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      Shared by Asst
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>

                <SidebarMenu.Nav
                  className="mb-3 menu_body_menu"
                  onClick={() => navigate("/employer/message")}
                >
                  <SidebarMenu.Nav.Link
                    className={`${
                      isActive("/employer/message") ? "active-link" : ""
                    }`}
                  >
                    <SidebarMenu.Nav.Icon className="px-2">
                      <IoChatboxEllipsesOutline className="navIcon" alt="" />
                    </SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      Message
                      {messagesNo.count > 0 && (
                        <div
                          style={{
                            backgroundColor: "#ff5c09",
                            height: "6px",
                            width: "6px",
                            marginTop: "-13px",
                            marginLeft: "110px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      )}
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </SidebarMenu.Nav>
              </SidebarMenu.Body>
            </SidebarMenu.Collapse>
          </SidebarMenu>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
