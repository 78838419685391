import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { GrLocation } from "react-icons/gr";
import { AiOutlineMobile } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Moment from "moment";
import cook from "../../../../Assets/Images/cook.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsWhatsapp, BsFillChatLeftTextFill } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import { FaRegClock } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import {
  EmployerMobileactivityLog,
  GetApplicationDetails,
  EmployerApplicationStatus,
} from "../../../../store/slices/employerprofile";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import ReportChefModal from "./ReportChefModal";
import InitApp1 from "../../../../utils/helpers/CreateOthersWeavy";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import { createChatRoom } from "../../cook_dashboard/pages/CreateChatRoom";
import ContactPivacyModal from "../../../../utils/helpers/ContactPrivacyModal";
import { ExtractResumeNameValue } from "../../../../utils/helpers/ResumeNameExtract";

function ApplicationDetails() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFullMobile, setShowFullMobile] = useState(false);
  const [mobileInteraction, setMobileInteraction] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [phoneNumber, setphoneNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [reportJob, setReportJob] = useState(false);
  const [cookId, setCookId] = useState();
  let user = JSON.parse(localStorage.getItem("user"));
  const [contactPrivacyModal, setContactPrivacyModal] = useState(false);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    getDetails();
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Application Details";
  }, []);

  const getDetails = () => {
    dispatch(GetApplicationDetails(id))
      .unwrap()
      .then((data) => {
        setData(data?.applicationdetails?.data);
        setCookId(data?.applicationdetails?.data?.cookId?.id);
        setphoneNumber(data?.applicationdetails?.data?.cookId?.mobile);
        setwhatsappNumber(
          data?.applicationdetails?.data?.cookId?.whatsappNumber
        );
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  const [clicked, setClicked] = useState(false);

  const toggleMobileView = () => {
    const item = {
      cookId: data?.cookId?.id,
      activity: "viewedmobilenumber",
    };
    if (data?.cookId?.smsContact === 0) {
      setContactPrivacyModal(true);
    } else if (showFullMobile === false) {
      dispatch(EmployerMobileactivityLog(item))
        .unwrap()
        .then((data) => {})
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
    setShowFullMobile(!showFullMobile);
  };

  console.log(data);
  const handleInteracction = async (value) => {
    const item = {
      cookId: data?.cookId?.id,
      activity: value,
    };
    try {
      const data1 = await dispatch(EmployerMobileactivityLog(item)).unwrap();
      if (value === "mobileinteraction") {
        window.location.href = `tel:${phoneNumber}`;
      } else if (value === "whatsappinteraction") {
        window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
      } else if (value === "chatinteraction") {
        let member2;
        let res;
        const weavyid = parseInt(localStorage.getItem("weavyId"));
        const member1 = parseInt(user?.data?.weavyId) || weavyid;
        member2 = parseInt(data?.cookId?.weavyId);

        const chatroomName = data?.cookId?.fullName;
        if (!data.cookId?.weavyId) {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer  ${API_PATHS.weavyKey}`,
            },
            body: JSON.stringify({
              uid: data?.cookId?.id,
              name: data?.cookId?.fullName,
            }),
          };
          const rees = await InitApp1(requestOptions, data?.cookId, "cook");
          member2 = parseInt(localStorage.getItem("weavyId2"));
          const memberIds = [member1, member2];
          res = await createChatRoom(chatroomName, memberIds);
        } else {
          member2 = parseInt(data?.cookId?.weavyId);
          const memberIds = [member1, member2];
          res = await createChatRoom(chatroomName, memberIds);
        }

        if (res?.id) {
          navigate("/hin/employer/message", {
            state: { res: res },
          });
        }
      }
    } catch ({ message }) {
      setModalMessage(message);
      setShowModal(true);
    }
  };

  const SetApplicationStatus = (a, b) => {
    console.log(a, b);
    const item = {
      id: id,
      applicationStatus: b,
    };
    dispatch(EmployerApplicationStatus(item))
      .unwrap()
      .then((data) => {
        getDetails();
        setModalMessage(data?.applicationstatus?.message);
        setsuccessModal(true);
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
      })
      .catch(({ message }) => {});
  };
  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <ReportChefModal
        reportJob={reportJob}
        setReportJob={setReportJob}
        id={cookId}
        setModalMessage={setModalMessage}
        setsuccessModal={setsuccessModal}
        setShowModal={setShowModal}
      />
      <ContactPivacyModal
        showModal={contactPrivacyModal}
        setShowModal={setContactPrivacyModal}
      />
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/employer/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link onClick={cancelHandler} className="bcrumb">
          ऍप्लिकेशन्स
        </Link>
        &nbsp;&bull; ऍप्लिकेशन्स विवरण
      </div>
      <Row className="mt-4">
        {isLoading ? (
          <Col lg={12} className="card">
            {ShowprofileSkeleton()}
          </Col>
        ) : (
          <Col lg={12} className="card">
            <Row className="pt-2">
              <Col lg={2} xs={8} md={3}>
                {data?.cookId?.jobSeeking === 1 && (
                  <p
                    className
                    style={{
                      background: "#ff5c09",
                      color: "#fff",
                      padding: "0px 0px 0px 5px",
                      margin: "-10px 0px 0px -15px",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    Looking for Job
                  </p>
                )}
              </Col>
              <Col lg={10} xs={4} md={9} />
              <Col lg={2} xs={8} md={3}>
                {data?.cookId?.dp ? (
                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      borderRadius: "15px",
                    }}
                    className="mt-2"
                    src={data?.cookId?.dp}
                    alt="user profile"
                  />
                ) : (
                  <img
                    style={{
                      height: "90px",
                      width: "90px",
                      borderRadius: "15px",
                    }}
                    className="mt-2"
                    src={cook}
                    alt="user profile"
                  />
                )}
                <p
                  style={{
                    fontSize: "11px",
                    color: "#8F8686",
                    marginTop: "5px",
                  }}
                >
                  <FaRegClock style={{ marginTop: "-2px" }} />
                  &nbsp;
                  {Moment.utc(data?.cookId?.lastLoginDateTime)
                    .local("Asia/Kolkata")
                    .startOf("seconds")
                    .fromNow()}
                </p>
              </Col>
              <Col xs={2} className="d-sm-block d-md-none d-lg-none">
                <div align="right">
                  {data?.applicationStatus === "applied" && (
                    <p
                      style={{
                        color: "#929402",
                        padding: "5px 10px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#FEFFD3",
                      }}
                      align="center"
                    >
                      Applied
                    </p>
                  )}
                  {data?.applicationStatus === "on Hold" && (
                    <p
                      style={{
                        color: "#000000",
                        padding: "5px 10px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#FFAF03",
                      }}
                      align="center"
                    >
                      On Hold
                    </p>
                  )}
                  {data?.applicationStatus === "shortlisted" && (
                    <p
                      style={{
                        color: "#0700F5",
                        padding: "5px 6px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "11px",
                        backgroundColor: "rgb(209 235 250)",
                      }}
                      align="center"
                    >
                      Shortlisted
                    </p>
                  )}
                  {data?.applicationStatus === "rejected" && (
                    <p
                      style={{
                        color: "#FF0606",
                        padding: "5px 6px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#FFDDD5",
                      }}
                      align="center"
                    >
                      Rejected
                    </p>
                  )}
                  {data?.applicationStatus === "hired" && (
                    <p
                      style={{
                        color: "#028D36",
                        padding: "5px 10px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#e7f8c6",
                      }}
                      align="center"
                    >
                      Hired
                    </p>
                  )}
                </div>
              </Col>
              <Col
                xs={1}
                align="right"
                className="d-sm-block d-md-none d-lg-none"
              >
                <Dropdown
                  style={{
                    marginTop: "-45px",
                    background: "none",
                  }}
                >
                  <Dropdown.Toggle
                    // id="dropdown-basic"
                    style={{
                      width: "25px",
                      height: "25px",
                      background: "none",
                      border: "none",
                    }}
                  >
                    <BiDotsVerticalRounded
                      style={{
                        marginTop: "40px",
                        fontSize: "20px",
                        color: "#000",
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "shortlisted")}
                    >
                      Shortlisted
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "hired")}
                    >
                      Hired
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "on Hold")}
                    >
                      On Hold
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "rejected")}
                    >
                      Rejected
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col lg={8} xs={12} md={6}>
                <h6 className="mt-2">{data?.cookId?.fullName}</h6>

                <p>
                  <GrLocation style={{ marginBottom: "2px" }} />
                  &nbsp;
                  {data?.cookId?.currentCityName}
                  <br />
                  <AiOutlineMobile style={{ marginBottom: "2px" }} />
                  &nbsp;+91-
                  {showFullMobile ? (
                    <span>{data?.cookId?.mobile}</span>
                  ) : (
                    <span>
                      {data?.cookId?.mobile?.toString()?.substring(0, 3)}xxxxxxx
                    </span>
                  )}
                  <span onClick={toggleMobileView}>
                    {showFullMobile ? (
                      <p></p>
                    ) : (
                      <Link
                        style={{
                          cursor: "pointer",
                          marginTop: "-25px",

                          color: "blue",
                        }}
                        className="ms-2"
                      >
                        View
                      </Link>
                    )}
                  </span>
                </p>
              </Col>
              <Col lg={1} md={1} className="d-none d-md-block d-lg-block">
                <div
                  align="right"
                  className="d-sm-none  d-md-block d-lg-block mt-2"
                >
                  {data?.applicationStatus === "applied" && (
                    <p
                      style={{
                        color: "#929402",
                        padding: "5px 10px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#FEFFD3",
                      }}
                      align="center"
                    >
                      Applied
                    </p>
                  )}
                  {data?.applicationStatus === "on Hold" && (
                    <p
                      style={{
                        color: "#000000",
                        padding: "5px 10px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#FFAF03",
                      }}
                      align="center"
                    >
                      On Hold
                    </p>
                  )}
                  {data?.applicationStatus === "shortlisted" && (
                    <p
                      style={{
                        color: "#0700F5",
                        padding: "5px 6px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "11px",
                        backgroundColor: "rgb(209 235 250)",
                      }}
                      align="center"
                    >
                      Shortlisted
                    </p>
                  )}
                  {data?.applicationStatus === "rejected" && (
                    <p
                      style={{
                        color: "#FF0606",
                        padding: "5px 6px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#FFDDD5",
                      }}
                      align="center"
                    >
                      Rejected
                    </p>
                  )}
                  {data?.applicationStatus === "hired" && (
                    <p
                      style={{
                        color: "#028D36",
                        padding: "5px 10px",
                        borderRadius: "10px",
                        width: "70px",
                        fontSize: "12px",
                        backgroundColor: "#e7f8c6",
                      }}
                      align="center"
                    >
                      Hired
                    </p>
                  )}
                </div>
              </Col>
              <Col
                lg={1}
                md={1}
                align="right"
                className="d-none d-md-block d-lg-block"
              >
                <Dropdown
                  style={{
                    marginTop: "-40px",
                    background: "none",
                    marginLeft: "-20px",
                  }}
                >
                  <Dropdown.Toggle
                    // id="dropdown-basic"
                    style={{
                      width: "25px",
                      height: "25px",
                      background: "none",
                      border: "none",
                    }}
                  >
                    <BiDotsVerticalRounded
                      style={{
                        marginTop: "40px",
                        fontSize: "20px",
                        color: "#000",
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "shortlisted")}
                    >
                      Shortlisted
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "hired")}
                    >
                      Hired
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "on Hold")}
                    >
                      On Hold
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => SetApplicationStatus(id, "rejected")}
                    >
                      Rejected
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col lg={9} xs={12} md={8} />
              <Col
                lg={3}
                xs={12}
                md={4}
                className="contacticonCard"
                align="right"
              >
                <Row>
                  {data?.cookId?.smsContact === 1 && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("mobileinteraction")}
                    >
                      <IoMdCall
                        style={{ color: "#1808B1", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                  {data?.cookId?.whatsappContact === 1 &&
                    data?.cookId?.whatsappNumber && (
                      <Col
                        lg={4}
                        className="contactCard"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleInteracction("whatsappinteraction")
                        }
                      >
                        <BsWhatsapp
                          style={{ color: "#059F14", fontSize: "25px" }}
                        />
                      </Col>
                    )}
                  {data?.cookId?.chatContact === 1 && (
                    <Col
                      lg={4}
                      className="contactCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleInteracction("chatinteraction")}
                    >
                      <BsFillChatLeftTextFill
                        style={{ color: "#F87F43", fontSize: "25px" }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row className="mt-4">
        {isLoading ? (
          <Col lg={6} className="card me-1 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col
            lg={6}
            xs={12}
            md={6}
            sm={12}
            className="card me-1 detailCard p-3"
          >
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              मूल जानकारी
            </h6>
            <p className="text-heading">के बारे में</p>
            <p className="text-details">{data?.cookId?.about}</p>
            <Row>
              <Col lg={6} xs={12}>
                <p className="text-heading">ईमेल</p>
                <p className="text-details">{data?.cookId?.email}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">जन्म की तारीख</p>

                {data?.cookId?.dob && (
                  <p className="text-details">
                    {Moment.utc(data.cookId.dob).format("DD-MM-YYYY")}
                  </p>
                )}
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">लिंग</p>
                {data?.cookId?.gender === 1 && (
                  <p className="text-details">पुरुष</p>
                )}
                {data?.cookId?.gender === 2 && (
                  <p className="text-details">महिला</p>
                )}
                {data?.cookId?.gender === 3 && (
                  <p className="text-details">Other</p>
                )}
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">शिक्षा</p>
                <p className="text-details">{data?.cookId?.qualification}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">ज्ञात भाषाएँ </p>
                <p className="text-details">
                  {data?.cookId?.languages?.join(", ")}
                </p>
              </Col>
            </Row>
          </Col>
        )}
        {isLoading ? (
          <Col lg={6} className="card ms-2 detailCard p-3">
            {DataSkeleton()}
          </Col>
        ) : (
          <Col lg={6} xs={12} md={6} className="card ms-2 detailCard p-3">
            <h6
              className="text-left"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              पता
            </h6>
            <p className="text-heading">पता पंक्ति</p>
            {data?.cookId?.addressLine1 && (
              <p className="text-details">
                {data?.cookId?.addressLine1} {data?.cookId?.addressLine2}
              </p>
            )}

            <Row>
              <Col lg={6} xs={12}>
                <p className="text-heading">क्षेत्र</p>
                {data?.cookId?.area && (
                  <p className="text-details">{data?.cookId?.area}</p>
                )}
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading"> शहर </p>
                <p className="text-details">{data?.cookId?.cityName}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading"> राज्य </p>
                {data?.cookId?.provinceName && (
                  <p className="text-details">{data?.cookId?.provinceName}</p>
                )}
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">पिन कोड</p>
                <p className="text-details">{data?.cookId?.pincode}</p>
              </Col>
              <Col lg={6} xs={12}>
                <p className="text-heading">लैंडमार्क</p>
                <p className="text-details">{data?.cookId?.landmark}</p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {isLoading ? (
        <Row>{DataSkeleton()}</Row>
      ) : (
        <Row className="mt-3 justify-content-center p-2 card mb-3">
          <Col lg={12} xs={12} md={12}>
            <h6
              className="text-left mt-1"
              style={{ fontWeight: "600", color: "#ff5c09 " }}
            >
              शेफ प्रोफ़ाइल
            </h6>
          </Col>
          <Col lg={12} xs={12} className="ps-2 mt-3 mb-2">
            <p className="text-heading">ज्ञात व्यंजन</p>
            <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
              {data?.cookId?.chefCuisines?.map((cookplanVal, index) => (
                <Col
                  xs={5}
                  sm={5}
                  md={3}
                  lg={2}
                  xl={2}
                  className="me-2"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    color: "black",
                    padding: "4px 8px",
                    margin: "8px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: "14px",
                    marginRight: "4px",
                    border: "solid 1px #ff5c09",
                    width: "auto",
                  }}
                >
                  {cookplanVal}
                </Col>
              ))}
            </Row>
          </Col>

          <Col>
            <Row>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">अनुभव</p>
                {data?.cookId?.chefExperience && (
                  <p className="text-details">
                    {data?.cookId?.chefExperience} Years
                  </p>
                )}
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">वर्तमान कंपनी</p>
                <p className="text-details">{data?.cookId?.currentCompany}</p>
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">वर्तमान सैलरी (प्रति माह)</p>
                {data?.cookId?.currentSalary && (
                  <p className="text-details">₹{data?.cookId?.currentSalary}</p>
                )}
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">अपेक्षित सैलरी (प्रति माह)</p>
                {data?.cookId?.expectedSalary && (
                  <p className="text-details">
                    ₹{data?.cookId?.expectedSalary}
                  </p>
                )}
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">स्किल्स </p>
                <div className="d-flex">
                  {data?.cookId?.skills?.map((el, ind) => (
                    <p className="text-details">
                      {el}
                      {ind === data?.cookId?.skills?.length - 1 ? "" : ","}
                      &nbsp;
                    </p>
                  ))}
                </div>
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">वर्तमान शहर</p>
                <p className="text-details">{data?.cookId?.currentCityName}</p>
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">
                  स्थानांतरित होने के लिए तैयार हैं?
                </p>
                {data?.cookId?.relocate === 0 && (
                  <p className="text-details">No</p>
                )}
                {data?.cookId?.relocate === 1 && (
                  <p className="text-details">Yes</p>
                )}
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">नौकरी की तलाश में</p>
                {data?.cookId?.jobSeeking === 0 && (
                  <p className="text-details">No</p>
                )}
                {data?.cookId?.jobSeeking === 1 && (
                  <p className="text-details">Yes</p>
                )}
              </Col>
              <Col xs={12} md={6} lg={6} className="ps-2 mt-1">
                <p className="text-heading">बायोडाटा</p>
                <p className="text-details" style={{ fontStyle: "italic" }}>
                  {ExtractResumeNameValue(data?.cookId?.resume)}
                </p>
                {data?.cookId?.resume && (
                  <p className="text-details">
                    <Link to={data?.cookId?.resume}>Download</Link>
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        {/*  <Col
          style={{ borderRadius: "15px", padding: "14px" }}
          className="card mb-5"
        >
       <div className="text-start">रेटिंग देने वाले प्रथम व्यक्ति बनें</div>
          <div
            className="text-start"
            style={{
              color: "#ff5c09",
              textDecoration: "underline",
              fontWeight: "600",
            }}
          >
            अब रेट करें
          </div> 
          </Col> */}
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col style={{ borderRadius: "15px", padding: "14px" }}>
          <Link
            onClick={() => setReportJob(true)}
            className="text-start mt-5"
            style={{
              color: "#8F8686",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            <u>इस प्रोफाइल को रिपोर्ट करें</u>
          </Link>
        </Col>
      </Row>
    </>
  );
}

export default ApplicationDetails;
