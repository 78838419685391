import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getCuisines } from "../../../../store/slices/config";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import {
  getcookProfile,
  editchefProfile,
} from "../../../../store/slices/cookprofile";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Modal2 from "../../auth_screens/Modal2";
import SuccessModal from "../../auth_screens/SuccessModal";

function EditChef() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Edit Chef Profile";
  }, []);

  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data?.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [chefExperienceVal, setchefExperienceVal] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [currentSalaryVal, setCurrentSalaryVal] = useState("");
  const [expectedSalaryVal, setExpectedSalaryVal] = useState("");
  const [skillsVal, setSkillsVal] = useState([]);
  const [skills, setSkills] = useState(true);
  const [currentCityName, setcurrentCityName] = useState("");
  const [relocate, setRelocate] = useState();
  const [jobSeeking, setjobSeeking] = useState();
  const [errors, setErrors] = useState({});
  const [coordnates, setCoordinates] = useState([]);
  const [resume, setResume] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [location, setLocation] = useState();
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);
  const [resumeName, setResumeName] = useState();
  const [resumError, setResumeError] = useState();
  const cuisineRef = useRef(null);
  const expRef = useRef(null);
  const currentSalRef = useRef(null);
  const expectedSalRef = useRef(null);

  React.useEffect(() => {
    dispatch(getcookProfile())
      .unwrap()
      .then((data) => {
        setchefCuisines(data?.user?.data?.chefCuisines);
        setchefExperienceVal(data?.user?.data?.chefExperience);
        setCurrentCompany(data?.user?.data?.currentCompany);
        setCurrentSalaryVal(data?.user?.data?.currentSalary);
        setExpectedSalaryVal(data?.user?.data?.expectedSalary);
        setSkillsVal(data?.user?.data?.skills);
        setcurrentCityName(data?.user?.data?.currentCityName);
        setResume(data?.user?.data?.resume);
        setRelocate(data?.user?.data?.relocate);
        setCoordinates(data?.user?.data?.currentCityCoordinates?.coordinates);
        setjobSeeking(data?.user?.data?.jobSeeking);
      })
      .catch(({ message }) => {
        // setsuccessModal(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  console.log(coordnates);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data?.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const cityinputRefchef = useRef(null);
  useEffect(() => {
    if (cityinputRefchef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRefchef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );
      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setCoordinates([]);
        } else {
          setErrors({
            ...errors,
            currentCityName: "",
          });

          setcurrentCityName(place.name);
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setCoordinates(coordinates);
        }
      });
      cityinputRefchef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRefchef.current.value.trim();

        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setCoordinates([]);
          setErrors({
            ...errors,
            currentCityName: "कृपया सुझाव ड्रॉप-डाउन से स्थान चुनें",
          });
        }
      });
    }
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "cuisine":
        setchefCuisines(value);
        validationErrors[name] =
          value === "" ? "कृपया ज्ञात व्यंजन दर्ज करें" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "chefExperienceVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        setchefExperienceVal(value);
        validationErrors[name] =
          value === "" || !isValidchefExperienceVal(value)
            ? "अधिकतम अनुभव की अनुमति 50 वर्ष है"
            : "";
        if (!value === "" && isValidchefExperienceVal(value)) {
          delete validationErrors[name];
        }
        break;

      case "currentCompany":
        setCurrentCompany(value);
        validationErrors[name] =
          value !== "" && !isValidcurrentCompany(value)
            ? "कृपया एक वैध कंपनी का नाम दर्ज करें"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "currentSalaryVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setCurrentSalaryVal(value);
        validationErrors[name] =
          value === ""
            ? "कृपया वर्तमान वेतन दर्ज करें"
            : value !== "" && !isValidcurrentSalary(value)
            ? "वेतन 5000 से अधिक होना चाहिए, यदि आप नए हैं तो कृपया 0 दर्ज करें"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "expectedSalaryVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setExpectedSalaryVal(value);
        validationErrors[name] =
          value === ""
            ? "कृपया अपना अपेक्षित वेतन दर्ज करें"
            : value !== "" && !isValidexpectedSalary(value)
            ? "वेतन 5000 से अधिक होना चाहिए"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "skillsVal":
        if (value.trim() !== "") {
          setSkillsVal(value.split(","));
        } else {
          setSkillsVal([]);
          setSkills(false);
        }

        validationErrors[name] =
          value.trim() !== "" && !isValidskills(value)
            ? "कृपया एक वैध कौशल दर्ज करें"
            : "";
        break;

      case "currentCityName":
        setcurrentCityName(value);
        validationErrors[name] =
          value === "" ? "कृपया एक वैध शहर का नाम दर्ज करें" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const isValidchefExperienceVal = (chefExperienceVal) => {
    return /^(0?[1-9]|[1-4][0-9]|50)$/.test(chefExperienceVal);
  };

  const isValidcurrentCompany = (currentCompany) => {
    return /^[a-zA-Z0-9\-'’, &@]{3,100}$/.test(currentCompany);
  };

  const isValidskills = (skillsVal) => {
    return /^[a-zA-Z0-9\- ',&]{2,100}$/.test(skillsVal);
  };

  const isValidcurrentSalary = (currentSalaryVal) => {
    return /^(0|([5-9]\d{3,5})|([1-9]\d{4,5}))$/.test(currentSalaryVal);
  };

  const isValidexpectedSalary = (expectedSalaryVal) => {
    return /^(5000|1000000|[5-9][0-9]{3}|[1-9][0-9]{4,5})$/.test(
      expectedSalaryVal
    );
  };

  const isValidcurrentCity = (currentCityName) => {
    return /^[a-zA-Z0-9\- ',&.]{3,100}$/.test(currentCityName);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};

    if (!chefCuisines?.length) {
      validationErrors.chefCuisines = "कृपया ज्ञात व्यंजन दर्ज करें";
    }
    if (!chefExperienceVal) {
      validationErrors.chefExperienceVal = "कृपया अपना अनुभव दर्ज करें";
    } else if (!isValidchefExperienceVal(chefExperienceVal)) {
      validationErrors.chefExperienceVal = "अधिकतम अनुभव की अनुमति 50 वर्ष है";
    }
    if (skillsVal !== "" && !isValidskills(skillsVal)) {
      validationErrors.skillsVal = "कृपया एक वैध कौशल दर्ज करें";
    }

    if (currentCompany !== "" && !isValidcurrentCompany(currentCompany)) {
      validationErrors.currentCompany = "कृपया एक वैध कंपनी का नाम दर्ज करें";
    }

    if (!currentCityName) {
      validationErrors.currentCityName = "कृपया शहर का नाम दर्ज करें";
    } else if (!isValidcurrentCity(currentCityName) || !coordnates?.length) {
      validationErrors.currentCityName =
        "कृपया मान्य शहर का नाम दर्ज करें name";
    }
    if (currentSalaryVal === "") {
      validationErrors.currentSalaryVal = "कृपया अपना वर्तमान वेतन दर्ज करें";
    } else if (!isValidcurrentSalary(currentSalaryVal)) {
      validationErrors.currentSalaryVal =
        "वेतन 5000 से अधिक होना चाहिए, यदि आप नए हैं तो कृपया 0 दर्ज करें";
    }
    if (!expectedSalaryVal) {
      validationErrors.expectedSalaryVal =
        "कृपया अपना अपेक्षित वेतन दर्ज करें ";
    } else if (!isValidexpectedSalary(expectedSalaryVal)) {
      validationErrors.expectedSalaryVal = "वेतन 5000 से अधिक होना चाहिए";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.chefCuisines) {
        cuisineRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.chefExperienceVal) {
        expRef.current.focus();
      } else if (validationErrors.currentSalaryVal) {
        currentSalRef.current.focus();
      } else if (validationErrors.expectedSalaryVal) {
        expectedSalRef.current.focus();
      } else if (validationErrors.currentCityName) {
        cityinputRefchef.current.focus();
      }
      return;
    } else {
      const chefExperience = parseInt(chefExperienceVal);
      const currentSalary = parseInt(currentSalaryVal);
      const expectedSalary = parseInt(expectedSalaryVal);

      let item = {
        jobSeeking: jobSeeking,
        chefCuisines: chefCuisines || undefined,
        skills: skillsVal,
        chefExperience: chefExperience || undefined,
        currentSalary: currentSalary,
        expectedSalary: expectedSalary || undefined,
        currentCompany: currentCompany || undefined,
        currentCityName: currentCityName || undefined,
        currentCityCoordinates: coordnates
          ? {
              type: "Point",
              coordinates: coordnates || [],
            }
          : undefined,
        relocate: relocate,
        resume: resume || undefined,
      };
      dispatch(editchefProfile(item))
        .unwrap()
        .then(() => {
          setModalMessage("Profile updated successfully");

          setsuccessModal(true);
          setTimeout(() => {
            navigate("/hin/cook/cook-profile");
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  };

  const handleChefCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    setchefCuisines(selected);

    validationErrors.chefCuisines =
      selected.length === 0 ? "कृपया ज्ञात व्यंजन दर्ज करें" : "";
    if (!selected.length === 0) {
      delete validationErrors.chefCuisines;
    }
    setErrors(validationErrors);
  };

  async function imageUpload(e) {
    setResumeError("");
    setResumeName(e.target.files[0].name);
    e.preventDefault();
    if (!e.target.files[0]) {
      setResumeError("कृपया एक फ़ाइल चुनें");
      return;
    }
    const fileSize = e.target.files[0].size;
    // Check if the file size exceeds 2 MB
    if (fileSize > 2 * 1024 * 1024) {
      setResumeError("कृपया 2MB से कम की फ़ाइल अपलोड करें");
      return;
    }

    var formdata = new FormData();
    formdata?.append("file", e.target.files[0]);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: { "x-access-token": token },
    };
    let response = await fetch(API_PATHS.uploadFileCook, requestOptions);
    let data = await response.json();
    setResume(data?.data?.location);
    // setMessage(data?.message);
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/cook/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull;&nbsp;
        <Link to="/hin/cook/cook-profile" className="bcrumb">
          कुक प्रोफ़ाइल
        </Link>
        &nbsp;&bull; एडिट शेफ प्रोफ़ाइल
      </div>
      <Form autoComplete="off" noValidate onSubmit={submitHandler}>
        <Row className="mt-1 gx-3 d-flex justify-content-center ">
          <Col lg={12} xs={11} className="mb-4">
            <Row className="=d-flex justify=-content-center align-items-center">
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "20px 40px",
                }}
              >
                <Col lg={12}>
                  <Row>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">ज्ञात व्यंजन *</label>
                        <div ref={cuisineRef}>
                          <Typeahead
                            clearButton
                            id="basic-typeahead-multiple"
                            labelKey="name"
                            multiple
                            className="input1"
                            onChange={handleChefCuisineChange}
                            options={options}
                            placeholder="ज्ञात व्यंजन"
                            selected={chefCuisines}
                            isInvalid={!!errors.chefCuisines}
                          />
                        </div>
                      </Form.Group>
                      <p style={{ color: "#DC3545", fontSize: "14px" }}>
                        {errors.chefCuisines}
                      </p>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label"> अनुभव *</label>
                        <Form.Control
                          type="text"
                          name="chefExperienceVal"
                          value={chefExperienceVal}
                          isInvalid={!!errors.chefExperienceVal}
                          isValid={!errors.chefExperienceVal}
                          onChange={handleChange}
                          ref={expRef}
                          className="input1"
                          onWheel={(e) => e.target.blur()}
                          placeholder="अनुभव दर्ज करें"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.chefExperienceVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">वर्तमान कंपनी</label>
                        <Form.Control
                          type="text"
                          name="currentCompany"
                          value={currentCompany}
                          isInvalid={!!errors.currentCompany}
                          isValid={!errors.currentCompany}
                          onChange={handleChange}
                          className="input1"
                          min="0"
                          placeholder="अपनी वर्तमान कंपनी का नाम दर्ज करें"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.currentCompany}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">
                          वर्तमान सैलरी (प्रति माह) *
                        </label>
                        <Form.Control
                          type="text"
                          name="currentSalaryVal"
                          value={currentSalaryVal}
                          isInvalid={!!errors.currentSalaryVal}
                          isValid={!errors.currentSalaryVal}
                          onChange={handleChange}
                          ref={currentSalRef}
                          className="input1"
                          onWheel={(e) => e.target.blur()}
                          placeholder="अपना वर्तमान वेतन दर्ज करें"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.currentSalaryVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">
                          अपेक्षित सैलरी (प्रति माह) *
                        </label>
                        <Form.Control
                          type="text"
                          name="expectedSalaryVal"
                          value={expectedSalaryVal}
                          isInvalid={!!errors.expectedSalaryVal}
                          isValid={!errors.expectedSalaryVal}
                          onChange={handleChange}
                          ref={expectedSalRef}
                          className="input1"
                          onWheel={(e) => e.target.blur()}
                          placeholder="अपना अपेक्षित वेतन दर्ज करें"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.expectedSalaryVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className="my-2">
                        <label className="mb-2 label">स्किल्स</label>
                        <Form.Control
                          type="text"
                          name="skillsVal"
                          value={skillsVal}
                          isInvalid={!!errors.skillsVal}
                          isValid={!errors.skillsVal}
                          onChange={(e) => {
                            setSkillsVal(e.target.value.split(",")); // Split the string into an array
                            handleChange(e);
                          }}
                          className="input1"
                          placeholder="अपना कौशल दर्ज करें"
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.skillsVal}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} className="p-3">
                      <Form.Group>
                        <label className="mb-2 label">र्तमान शहर *</label>

                        <Form.Control
                          type="text"
                          name="currentCityName"
                          value={currentCityName}
                          isInvalid={!!errors.currentCityName}
                          isValid={!errors.currentCityName}
                          onChange={handleChange}
                          className="input1"
                          ref={cityinputRefchef}
                          placeholder="वर्तमान शहर का नाम दर्ज करें"
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.currentCityName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6} className="p-3">
                      <Form.Group className=" justify-content-start align-items-center">
                        <label className="mb-2 label">
                          स्थानांतरित होने के लिए तैयार हैं?
                        </label>
                        <Row>
                          <Col lg={3} md={3} xs={6}>
                            <Form.Check
                              type="switch"
                              id="yes"
                              label="Yes"
                              name="relocate"
                              value={relocate}
                              onChange={() => setRelocate(1)}
                              checked={relocate === 1}
                              className="switchBtn"
                              required
                            />
                          </Col>
                          <Col lg={3} md={3} xs={6}>
                            <Form.Check
                              type="switch"
                              id="no"
                              label="No"
                              name="relocate"
                              value={relocate}
                              onChange={() => setRelocate(0)}
                              checked={relocate === 0}
                              className="switchBtn"
                              required
                            />
                          </Col>
                        </Row>
                        <Form.Control.Feedback type="invalid">
                          You must agree before submitting.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group className=" justify-content-start align-items-center">
                        <label className="mb-2 label">नौकरी की तलाश में</label>
                        <Row>
                          <Col lg={3} md={3} xs={6}>
                            <Form.Check
                              type="switch"
                              id="yes"
                              label="Yes"
                              name="householdVesselWash"
                              value={jobSeeking}
                              onChange={() => setjobSeeking(1)}
                              checked={jobSeeking === 1}
                              className="switchBtn"
                            />
                          </Col>
                          <Col lg={3} md={3} xs={6}>
                            <Form.Check
                              type="switch"
                              id="no"
                              label="No"
                              name="householdVesselWash"
                              value={jobSeeking}
                              onChange={() => setjobSeeking(0)}
                              checked={jobSeeking === 0}
                              className="switchBtn"
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="p-3">
                      <Form.Group>
                        <label className="mb-2 label">बायोडाटा</label>
                        <br />
                        {resumeName ? (
                          <label for="fileInput" className="custom-button">
                            {resumeName}
                          </label>
                        ) : (
                          <label for="fileInput" className="custom-button">
                            रेज़्यूमे अपलोड करें
                          </label>
                        )}

                        <Form.Control
                          type="file"
                          id="fileInput"
                          name="resume"
                          isInvalid={!!resumError}
                          isValid={!resumError}
                          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                          onChange={(e) => imageUpload(e)}
                          className="input1"
                        />
                        <Form.Control.Feedback type="invalid">
                          {resumError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Row className="d-flex justify-content-center mt-5">
                  <Col lg={4} xs={8}>
                    <div className="d-flex justify-content-around my-2 align-items-center">
                      <button
                        onClick={() => navigate(-1)}
                        className="cancelBtn"
                      >
                        रद्द कीजिये
                      </button>
                      <Button
                        type="submit"
                        disabled={isdisabled}
                        className="saveBtn ms-2"
                      >
                        जमा करना
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
      <br />
      <br />
    </>
  );
}

export default EditChef;
