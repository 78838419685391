import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

const logout =()=>{
  localStorage.clear();
  localStorage.setItem("logout", Date.now().toString());
  localStorage.setItem("role", "employer");
  }


class EmployerService {


    static getemployerProfile() {
        let api = '';
        api = API_PATHS.getemployerProfile;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
            .get(api, { headers: {"x-access-token" : token}})
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
    }

    static getSharedProfiles(item) {
      let api = '';
      api = API_PATHS.getSharedprofile   +`?limit=${item.limit}&page=${item.page}`;
      let user = JSON.parse(localStorage.getItem("user"));
      const token = user.data.token;
      return axios
          .get(api, { headers: {"x-access-token" : token}})
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
    }
    
    static getSharedProfileDetails(id) {
    
        let api = "";
        api = API_PATHS.getsharedprofileDetails +"?cookId="+id;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .get(api,  { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

    static getemployerDashbaordData() {
      let api = '';
      api = API_PATHS.getDashboardData;
      let user = JSON.parse(localStorage.getItem("user"));
      const token = user.data.token;
      const config = {
        headers: {
          "x-access-token": token,
        },
      };
      return axios
          .get(api,config)
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
  }
    static getplanHistory() {
      let api = '';
      api = API_PATHS.employerplanHistory;
      let user = JSON.parse(localStorage.getItem("user"));
      const token = user.data.token;
      return axios
          .get(api, { headers: {"x-access-token" : token}})
          .then((response) => {
              if (response.data) {
                  // localStorage.setItem("user", JSON.stringify(response.data));
              }
              return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
  }

  static getAllApplications(item) {
    let api = '';
    api = API_PATHS.getapplicationsReceived +`?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const token = user.data.token;
    return axios
        .get(api, { headers: {"x-access-token" : token}})
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

static getAllApplicationsdata() {
  let api = '';
  api = API_PATHS.getapplicationsReceived;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
      .get(api, { headers: {"x-access-token" : token}})
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

static getJobApplications(item) {
  let api = '';
  api = API_PATHS.getapplicationsReceived +`?limit=${item.limit}&page=${item.page}&jobId=${item.jobId}`;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
      .get(api, { headers: {"x-access-token" : token}})
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}


//get all event requests
static getEventRequests(item) {
  let api = '';
  api = API_PATHS.geteventrequests +`?limit=${item.limit}&page=${item.page}`;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
      .get(api, { headers: {"x-access-token" : token}})
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}


//get all event requests
static getRequirementRequests(item) {
  let api = '';
  api = API_PATHS.houseinterests +`?limit=${item.limit}&page=${item.page}`;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
      .get(api, { headers: {"x-access-token" : token}})
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

    //edit employer service
    static editemployerProfile(item) {
        let api = "";
        api = API_PATHS.editemployerProfile;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .put(api, item, { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

      static UpdateLogin() {
        let api = "";
        api = API_PATHS.updateLastLoginEmployer;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
      
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        return axios
          .put(api, {},config)
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }


      //post job service for employer
      static PostJob(item) {
        let api = "";
        api = API_PATHS.postjob;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .post(api, item, { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

      //editjob service for employer
      static editJob(item) {
        let api = "";
        api = API_PATHS.editjob;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .put(api, item, { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

      //get job service
      static getJob(id) {
        let api = "";
        api = API_PATHS.getjob +"?id="+id;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .get(api,  { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }


      static getChefDetails(id) {
        let api = "";
        api = API_PATHS.viewchefDetails +"?id="+id+"&cookType=chef";
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .get(api,  { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

      static getApplicationDetails(id) {
        let api = "";
        api = API_PATHS.viewApplicationDetail +"?id="+id;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .get(api,  { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

      static getAllJob() {
        let api = "";
        api = API_PATHS.getjob;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        return axios
          .get(api,  { headers: { "x-access-token": token } })
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

//employer mobile number activity log
        static employerMobileactivityLog(item) {
        let api = "";
        api = API_PATHS.employermobieactivity;
        let user = JSON.parse(localStorage.getItem("user"));
        const token = user.data.token;
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        return axios
          .post(api, item, config)
          .then((response) => {
            if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
          }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
      }

//find chef 

static findchef(item) {
  let api = "";
  api = API_PATHS.findchef;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}


static topchef(item) {
  let api = "";
  api = API_PATHS.findchef;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

static employerAplicationSatus(item) {
  let api = "";
  api = API_PATHS.employerapplicationstatus;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .put(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

static clientEventSatus(item) {
  let api = "";
  api = API_PATHS.eventrequeststatus;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .put(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

//view shortlisting chefs
static viewshortlistingchefs(item) {
  let api = "";
  api = API_PATHS.viewshortlistchef +`?limit=${item?.limit}&page=${item?.page}&cookType=${item?.cookType}`;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}


static viewEmployershortlistingchefs(item) {
  let api = "";
  api = API_PATHS.viewshortlistchef +`?limit=${item?.limit}&page=${item?.page}`;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

//udate weavyid employer
static updateEmployerWeavy(item) {
  let api = "";
  api = API_PATHS.updateemployerWeavyid;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .put(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        localStorage.setItem("weavyId", response?.data?.data?.weavyId)
      }
      return response.data;
    })
}



 //employerjobdetails service
static getEmployerJobDetails(id) {
  let api = "";
  api = API_PATHS.employerjobdetails +"?id="+id;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .get(api,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

static employerReportchefService(item) {
  let api = '';
  api = API_PATHS.reportchefprofile 
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
      .post(api, item, { headers: {"x-access-token" : token}})
      .then((response) => {
          if (response.data) {
              // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
      }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}



//for shortlisting for chefs
static shortlistchefs(item) {
  let api = "";
  api = API_PATHS.shortlistcook;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}

//store rating for cook 
static storeCookRating(item) {
  let api = "";
  api = API_PATHS.cookrating;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  return axios
    .post(api,item,  { headers: { "x-access-token": token } })
    .then((response) => {
      if (response.data) {
    
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}


// for removing from shortlists
static removeshortlistchefs(item) {
  let api = "";
  api = API_PATHS.removeshortchef;
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },
    data:item,
  };
  return axios
    .delete(api,config)
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    }).catch((Error) => {
        if (Error?.response?.status === 401) {
         
          logout();
         
        }
      });
}



static async getemployerAdvertisement(item) {
  let api = '';
  let user = JSON.parse(localStorage.getItem("user"));
  const token = user.data.token;
  api = API_PATHS.getemployerAdvertisements + `?limit=${item.limit}&page=${item.page}&usertype=${item.usertype}`;
  const response = await axios.get(api, { headers: { "x-access-token": token } });
  return response.data; // Extract only the serializable data
}




    static removeuserDetails() {
        localStorage.removeItem("user");
    }

    static getuserDetails() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default EmployerService;