import React from "react";
import SideMenu from "./SideMenu";
import { Col, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import DashHome from "./pages/DashHome";
import FindChefs from "./pages/FindChefs";
import ChefProfile from "./pages/ChefProfile";
import ManageJobs from "./pages/ManageJobs";
import Profile from "./pages/EmployerProfile";
import EditProfile from "./pages/EditProfile";
import PostJob from "./pages/PostJob";
import EmployerNavBar from "./EmployerNavBar";
import EmployerPlans from "./pages/EmployerPlans";
import EditJob from "./pages/editjob";
import PlanHistory from "./pages/planhistory";
import DashFooter from "../nav_bar/dashboardfooter";
import ShortlistedChefs from "./pages/viewshortlisted";
import EmployerCheckout from "./pages/EmployerCheckout";
import Applications from "./pages/Applications";
import ApplicationDetails from "./pages/applicationdetails";
import JobDetails from "./pages/JobDetails";
import Changepassword from "./pages/ChangePassword";
import ContactPrivacy from "./pages/ContactPrivacy";
import EmployerSettings from "./pages/EmployerSettings";
import JobApplications from "./pages/JobApplications";
import ChatSupport from "../cook_dashboard/pages/ChatSupport";
import ChatBothind from "../../../utils/helpers/ChatBothind/HelpChatSupport";
import SharedProfileDetails from "./pages/SharedProfileDetails";
import GetSharedProfile from "./pages/GetSharedProfiles";
import ShortlistedChefProfile from "./pages/shortlisted-chef-profile";
import EmployerErrorPage from "../../employer/404";
function Dashboard() {
  return (
    <>
      <EmployerNavBar />
      <Row className="d-flex justify-content-center">
        <Col lg={2} xl={2} md={3} xxl={2} className="d-none d-lg-block dashbar">
          <SideMenu />
        </Col>
        <Col xs={11} md={9} lg={7} className="mx-3 mb-5">
          <Routes>
            <Route path="/dashboard" element={<DashHome />} />
            <Route path="/find-chef" element={<FindChefs />} />
            <Route path="/shortlisted-chefs" element={<ShortlistedChefs />} />

            <Route path="/chef-details/:id" element={<ChefProfile />} />
            <Route
              path="/shortlisted-chef-details/:id"
              element={<ShortlistedChefProfile />}
            />
            <Route path="/job-details/:id" element={<JobDetails />} />
            <Route path="/manage-jobs" element={<ManageJobs />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/post-job" element={<PostJob />} />
            <Route path="/edit-job/:id" element={<EditJob />} />
            <Route path="/plans" element={<EmployerPlans />} />
            <Route path="/checkout/:id" element={<EmployerCheckout />} />
            <Route path="/change-password" element={<Changepassword />} />
            <Route path="/contact-privacy" element={<ContactPrivacy />} />
            <Route path="/settings" element={<EmployerSettings />} />
            <Route path="/shared-profiles" element={<GetSharedProfile />} />
            <Route
              path="/shared-profile-details/:id"
              element={<SharedProfileDetails />}
            />
            <Route path="/plan-history" element={<PlanHistory />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/message" element={<ChatSupport />} />

            <Route path="/job-application/:id" element={<JobApplications />} />

            <Route
              path="/application-details/:id"
              element={<ApplicationDetails />}
            />
            <Route path="*" element={<EmployerErrorPage />} />
          </Routes>
        </Col>
      </Row>
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          bottom: 50, // Adjust the distance from the bottom as needed
          right: 30, // Adjust the distance from the right as needed
        }}
      >
        <ChatBothind />
      </div>
      <DashFooter />
    </>
  );
}

export default Dashboard;
