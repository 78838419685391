import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import { cookregister } from "../../../store/slices/auth";
import { useDispatch } from "react-redux";
import AuthNavMenu from "../nav_bar/AuthNavMenu";
import Footer from "../nav_bar/Footer";
import login_banner from "../../../Assets/Images/cook-signup.jpg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import Modal2 from "./Modal2";
import SuccessModal from "./SuccessModal";
import Fingerprint2 from "fingerprintjs2";

function CookSignup() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [mobileNumber, setMobileNumber] = useState("");
  const [cook, setCook] = useState(false);
  const [chef, setChef] = useState(false);
  // console.log(cookType);
  const [partyCook, setPartyCook] = useState(false);
  // console.log(partyCook);
  const [genderVal, setGenderVal] = useState();
  const [confirmPassword, setconfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [mac, setMac] = useState();

  useEffect(() => {
    // Generate the fingerprint
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);

      // Log or use the fingerprint as needed
      // console.log("Device Fingerprint:", fingerprint);
      setMac(fingerprint);
    });
  }, []);

  // storing errors
  const [errors, setErrors] = useState({});
  useEffect(() => {
    document.title = "CookandChef | Cook Signup";
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, checked } = e.target;
    let value = e.target.value;
    switch (name) {
      case "fullName":
        setFullName(value);
        validationErrors[name] =
          value.trim() === "" || !isValidName(value)
            ? "कृपया एक मान्य नाम दर्ज करें"
            : "";
        if (value.trim() !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;
      case "email":
        setEmail(value);
        validationErrors[name] =
          value.trim() === "" || !isValidEmail(value)
            ? "कृपया एक मान्य ईमेल दर्ज करें"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;
      case "mobileNumber":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setMobileNumber(value);
        validationErrors[name] =
          value.trim() === "" || !isValidmobile(value)
            ? "कृपया एक वैध मोबाइल नंबर दर्ज करें"
            : "";
        if (value.trim().length === "") {
          validationErrors[name] = "";
        }
        break;
      case "password":
        setPassword(value);
        validationErrors[name] =
          value.trim() === "" || !isValidPassword(value)
            ? "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए"
            : "";
        if (value.trim() !== "" && isValidPassword(value)) {
          validationErrors[name] = "";
        }
        break;

      case "confirmPassword":
        setconfirmPassword(value);
        validationErrors[name] =
          value.trim() === "" || value === password
            ? ""
            : "Password and पासवर्ड की पुष्टि कीजिये should be same";
        break;

      case "genderVal":
        setGenderVal(value);
        validationErrors[name] =
          value.trim() === "" ? "कृपया अपना लिंग चुनें" : "";
        break;

      case "cookType":
        setCook(checked);
        if (checked && chef) {
          validationErrors.cookType =
            "Both House Cook and Chef can't be selected.";
        } else {
          validationErrors.cookType = "";
        }
        break;
      case "chefType":
        setChef(checked);
        if (checked && cook) {
          validationErrors.cookType =
            "Both House Cook and Chef can't be selected.";
        } else {
          validationErrors.cookType = "";
        }
        break;
      case "partyCook":
        setPartyCook(checked);
        if (chef && cook) {
          validationErrors.cookType =
            "Both House Cook and Chef can't be selected.";
        } else {
          validationErrors.cookType = "";
        }
        break;

      default:
        break;
    }

    setErrors(validationErrors);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9_ ']{2,}$/.test(name);
  };

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  async function signUp(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!fullName.trim()) {
      validationErrors.fullName = "कृपया अपना नाम दर्ज करें";
    } else if (!isValidName(fullName)) {
      validationErrors.fullName = "Name must be atleast three characters";
    }
    if (!email.trim()) {
      validationErrors.email = "अपना ईमेल दर्ज करें";
    } else if (!isValidEmail(email)) {
      validationErrors.email = "कृपया एक मान्य ईमेल दर्ज करें";
    }
    if (!mobileNumber.trim()) {
      validationErrors.mobileNumber = "अपना मोबाइल नंबर दर्ज करें";
    } else if (!isValidmobile(mobileNumber)) {
      validationErrors.mobileNumber = "कृपया एक वैध मोबाइल नंबर दर्ज करें";
    }
    if (!password.trim()) {
      validationErrors.password = "Please enter password";
    } else if (!isValidPassword(password)) {
      validationErrors.password =
        "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए";
    }

    if (!confirmPassword.trim()) {
      validationErrors.confirmPassword = "कृपया पुष्टि पासवर्ड दर्ज करें";
    } else if (!isValidPassword(confirmPassword)) {
      validationErrors.confirmPassword =
        "पासवर्ड अक्षरांकीय और न्यूनतम 8 अक्षर का होना चाहिए";
    } else if (password !== confirmPassword) {
      validationErrors.confirmPassword =
        "पासवर्ड की पुष्टि कीजिये should be same as password";
    }
    if (!genderVal) {
      validationErrors.genderVal = "कृपया अपना लिंग चुनें";
    }
    if (!(chef || cook || partyCook)) {
      validationErrors.cookType = "कृपया चयन कीजिए atleaset one role";
    }

    if (cook && chef) {
      validationErrors.cookType = "Both House Cook and Chef can't be selected.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    let registerIP = "0.0.0.0";
    let memberType = 1;
    let registerMAC = mac;
    let webAccess = 1;

    let mobile = parseInt(mobileNumber);
    let gender = parseInt(genderVal);
    let item = {
      fullName,
      email,
      mobile,
      password,
      gender,
      memberType,
      registerIP,
      registerMAC,
      webAccess,
      cookType: cook == false && chef == false ? undefined : cook ? 1 : 2,
      partyCook: partyCook ? 1 : 0,
    };

    dispatch(cookregister(item))
      .unwrap()
      .then(() => {
        setModalMessage("OTP has been sent to your मोबाइल नंबर");
        setsuccessModal(true);
        setTimeout(() => {
          navigate("/cook-verify-otp");
        }, 1000);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        // setShowModal(true);
      });
  }

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />

      <SuccessModal
        showModal={successModal}
        modalMessage={modalMessage}
        setShowModal={setsuccessModal}
      />
      <AuthNavMenu />
      <Container>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="mt-3"
        >
          <Col
            lg={5}
            className="d-none d-lg-block"
            style={{ background: "#fff" }}
          >
            <img src={login_banner} alt="" style={{ height: "600px" }} />
          </Col>

          <Col
            className="d-flex justify-content-center align-items-center flex-column"
            lg={5}
            xs={11}
            md={12}
            xl={5}
            style={{
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                overflowY: "scroll",
                height: "600px",
                width: "80%",
              }}
            >
              <p className="h3 text-center mt-3">Cook Sign Up</p>
              <p style={{ color: "red", textWrap: "wrap" }}>{modalMessage}</p>
              <Form
                noValidate
                autoComplete="off"
                onSubmit={signUp}
                className="text-center p-2"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Form.Group
                  className="d-flex flex-column align-items-start"
                  controlId="formRadio"
                >
                  <div className="d-flex flex-row align-items-center my-2">
                    <Row>
                      <Col xs={5} lg={3} md={3} align="left">
                        <Form.Check
                          className=""
                          type="checkbox"
                          label="Chef"
                          id="chefCheckbox"
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "chefType",
                                checked: e.target.checked,
                              },
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} lg={5} md={5} align="left">
                        <Form.Check
                          className="mx-3 "
                          type="checkbox"
                          label="House Cook"
                          id="houseCookCheckbox"
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "cookType",
                                checked: e.target.checked,
                              },
                            })
                          }
                        />
                      </Col>
                      <Col xs={6} lg={4} md={4} align="left">
                        <Form.Check
                          type="checkbox"
                          label="Party Cook"
                          id="partyCookCheckbox"
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "partyCook",
                                checked: e.target.checked,
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
                <div className="text-start">
                  {errors.cookType && (
                    <div style={{ fontSize: "14px", color: "#dc3545" }}>
                      {errors.cookType}
                    </div>
                  )}
                </div>

                <Form.Group className="mb-4 mt-3">
                  <Form.Control
                    className="input1"
                    type="text"
                    name="fullName"
                    value={fullName}
                    onChange={handleChange}
                    isInvalid={!!errors.fullName}
                    placeholder="अपना नाम दर्ज करें"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="input1"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    placeholder="Enter your email"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control
                    className="input1"
                    type="text"
                    name="mobileNumber"
                    onChange={handleChange}
                    value={mobileNumber}
                    isInvalid={!!errors.mobileNumber}
                    maxLength={10}
                    placeholder="Enter your मोबाइल नंबर"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.mobileNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4 position-relative">
                  <Form.Control
                    className="input1"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    name="password"
                    value={password}
                    isInvalid={!!errors.password}
                    placeholder="Enter password"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "20%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEye style={{ fontSize: "18px" }} />
                    ) : (
                      <AiOutlineEyeInvisible style={{ fontSize: "18px" }} />
                    )}
                  </span>
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as="div" className="mb-4 position-relative">
                  <Form.Control
                    className="input1"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                    placeholder="पासवर्ड की पुष्टि कीजिये"
                  />

                  <span
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "20%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <AiOutlineEye
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    )}
                  </span>
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Control
                    as="select"
                    name="genderVal"
                    className="input1"
                    value={genderVal}
                    onChange={handleChange}
                    isInvalid={!!errors.genderVal}
                    isValid={errors.genderVal}
                  >
                    <option value="" selected disabled hidden>
                      Select Gender
                    </option>
                    <option value="1">पुरुष</option>
                    <option value="2">महिला</option>
                    <option value="3">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.genderVal}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="text-start mb-4">
                  <p
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: "12px",
                    }}
                  >
                    By clicking Sign Up, you agree to our
                    <Link
                      to="https://cookandchef.in/terms-and-conditions"
                      target="_blank"
                      className="terms_and_conditions"
                    >
                      &nbsp; terms and conditions
                    </Link>
                    and&nbsp;
                    <Link
                      to="https://cookandchef.in/privacy-policy"
                      target="_blank"
                      className="terms_and_conditions"
                    >
                      privacy policy
                    </Link>
                  </p>
                  <div></div>
                </Form.Group>
                <Button
                  className="mb-2 login_btn mt-2"
                  disabled={isdisabled}
                  type="submit"
                >
                  Sign Up
                </Button>
                <p className="text-muted mt-3">
                  Already have an account?&nbsp;
                  <NavLink to="/login">
                    <span className="forgot_password">Login</span>
                  </NavLink>
                </p>
                <p className="text-muted">
                  Are you a catering?&nbsp;
                  <NavLink to="/catering_signup">
                    <span className="forgot_password">Catering SignUp</span>
                  </NavLink>
                </p>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default CookSignup;
