//-------------------------------IMPORT START--------------------------//
import React, { useEffect, useState } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { TbCoinRupee } from "react-icons/tb";
import { TbChefHat } from "react-icons/tb";
import { SlBriefcase } from "react-icons/sl";
import { SlNotebook } from "react-icons/sl";
import { IoSettingsOutline } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { API_PATHS } from "../../utils/constants/api.constants";
import EnableProfileModal from "../auth_screens/EnableProfileModal";
import { getemployerProfile } from "../../store/slices/employerprofile";

//-------------------------------IMPORT END--------------------------//

function SideMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(true);
  const [enableProfile, setEnableProfile] = useState(false);
  const [expand, setExpand] = useState({
    jobs: false,
    plans: false,
  });
  const [data, setData] = useState();
  const data1 = useSelector((state) => state.employerData.empProfileData);
  const weavyId = localStorage.getItem("weavyId");
  const [weavyToken, setWeavyToken] = useState();
  const messagesNo = useSelector(
    (state) => state.configData.messageNotifications
  );

  useEffect(() => {
    setData(data1?.user?.data);
  }, [data1]);

  const fetchData = async () => {
    try {
      const token = await fetchAuthToken();
      setWeavyToken(token);
    } catch (error) {
      console.error("Error fetching authentication token:", error);
    }
  };

  useEffect(() => {
    if (weavyId) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 50 * 60 * 1000); // 50 minutes

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [weavyId]);

  const setWeavyTokenInLocalStorage = (token, expirationTime) => {
    localStorage.setItem("WeavyToken", token);
    localStorage.setItem("WeavyTokenExpiry", expirationTime.toString());
  };

  const fetchAuthToken = async () => {
    try {
      const storedToken = localStorage.getItem("WeavyToken");
      const storedTokenExpiry = localStorage.getItem("WeavyTokenExpiry");

      if (storedToken && storedTokenExpiry) {
        const currentTime = new Date().getTime();
        if (currentTime < parseInt(storedTokenExpiry, 10)) {
          return storedToken;
        }
      }
      const response = await fetch(
        `https://${API_PATHS.weavyId}.weavy.io/api/users/${data1?.data?.id}/tokens`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${API_PATHS.weavyKey}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.access_token;
        localStorage.setItem("WeavyToken", token);
        if (token) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          setWeavyTokenInLocalStorage(token, expirationTime);

          return token;
        } else {
          throw new Error("Authentication token not received.");
        }
      } else {
        throw new Error(
          `Error fetching authentication token: ${response.status} - ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error fetching authentication token:", error);
      throw error;
    }
  };

  const toggleCollapse = (section) => {
    setExpand((prevExpand) => ({
      ...Object.fromEntries(Object.keys(prevExpand).map((key) => [key, false])),
      [section]: !prevExpand[section],
    }));
  };
  const handleNavTitleClick = (section, e) => {
    e.stopPropagation();
    e.preventDefault();

    toggleCollapse(section);
  };

  const gotoPostJob = () => {
    if (data.status === 2) {
      setEnableProfile(true);
    } else {
      navigate("/employer/post-job");
    }
  };

  React.useEffect(() => {
    dispatch(getemployerProfile())
      .unwrap()
      .then((data) => {
        if (data?.user?.response?.status === 401) {
          logout();
          return;
        }
        setData(data?.user?.data);
      })
      .catch(({ message }) => {});
  }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/employer-login");
  };

  const isActive = (path) => window.location.pathname === path;

  return (
    <>
      <EnableProfileModal
        showModal={enableProfile}
        setShowModal={setEnableProfile}
      />

      <div className="mt-4">
        <SidebarMenu className="" expanded={collapse}>
          <SidebarMenu.Collapse className="sidebar">
            <SidebarMenu.Body>
              <SidebarMenu.Nav
                className="mb-3"
                onClick={() => navigate("/employer/dashboard")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/employer/dashboard") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <IoHomeOutline className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Dashboard</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/employer/profile")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/employer/profile") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <FaRegUser className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Profile</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Sub className="mb-1 menu_body_menu">
                <SidebarMenu.Sub.Toggle
                  onClick={(e) => handleNavTitleClick("plans", e)}
                  className="justify-content-left align-items-left"
                >
                  <SidebarMenu.Nav.Icon className="pe-2">
                    <TbCoinRupee
                      className="navIcon"
                      style={{
                        height: "22px",
                        width: "22px",
                        marginLeft: "1px",
                      }}
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Plans</SidebarMenu.Nav.Title>
                  {expand.plans === false ? (
                    <BiChevronDown style={{ marginLeft: "80px" }} />
                  ) : (
                    <BiChevronUp style={{ marginLeft: "80px" }} />
                  )}
                </SidebarMenu.Sub.Toggle>
                <SidebarMenu.Sub.Collapse in={expand.plans} align="left">
                  <div>
                    <SidebarMenu.Nav
                      className="justify-content-left align-items-left"
                      onClick={() => navigate("/employer/plans")}
                    >
                      <SidebarMenu.Nav.Link>
                        <SidebarMenu.Nav.Title
                          className={`${
                            isActive("/employer/plans") ? "active-links" : ""
                          }`}
                          style={{ marginLeft: "35px" }}
                        >
                          Buy Plans
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>

                    <SidebarMenu.Nav
                      className="justify-content-left align-items-left"
                      onClick={() => navigate("/employer/plan-history")}
                    >
                      <SidebarMenu.Nav.Link>
                        <SidebarMenu.Nav.Title
                          className={`${
                            isActive("/employer/plan-history")
                              ? "active-links"
                              : ""
                          }`}
                          style={{ marginLeft: "35px" }}
                        >
                          My Plans
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>
                  </div>
                </SidebarMenu.Sub.Collapse>
              </SidebarMenu.Sub>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/employer/find-chef")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/employer/find-chef") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <TbChefHat
                      style={{ height: "21px", width: "21px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Find Chef</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Sub className="mb-1 menu_body_menu">
                <SidebarMenu.Sub.Toggle
                  // onClick={() => setExpand(!expand)}

                  onClick={(e) => handleNavTitleClick("jobs", e)}
                  className="d-flex justify-content-left align-items-left"
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <SlBriefcase
                      className="navIcon"
                      style={{ marginLeft: "-2px" }}
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>
                    {/* Submenu title */}Jobs
                  </SidebarMenu.Nav.Title>
                  {expand.jobs === false ? (
                    <BiChevronDown style={{ marginLeft: "85px" }} />
                  ) : (
                    <BiChevronUp style={{ marginLeft: "85px" }} />
                  )}
                </SidebarMenu.Sub.Toggle>
                <SidebarMenu.Sub.Collapse in={expand.jobs} align="left">
                  <div>
                    <SidebarMenu.Nav
                      className="justify-content-left align-items-left mt-1"
                      onClick={() => gotoPostJob()}
                    >
                      <SidebarMenu.Nav.Link>
                        <SidebarMenu.Nav.Title
                          className={`${
                            isActive("/employer/post-job") ? "active-links" : ""
                          }`}
                          style={{ marginLeft: "35px" }}
                        >
                          Post Job
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>
                    <SidebarMenu.Nav
                      className="justify-content-left align-items-left mt-1"
                      onClick={() => navigate("/employer/manage-jobs")}
                    >
                      <SidebarMenu.Nav.Link>
                        <SidebarMenu.Nav.Title
                          className={`${
                            isActive("/employer/manage-jobs")
                              ? "active-links"
                              : ""
                          }`}
                          style={{ marginLeft: "35px" }}
                        >
                          Manage Jobs
                        </SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </SidebarMenu.Nav>
                  </div>
                </SidebarMenu.Sub.Collapse>
              </SidebarMenu.Sub>
              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/employer/applications")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/employer/applications") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <SlNotebook className="navIcon" />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Applications</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/employer/shared-profiles")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/employer/shared-profiles") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <MdSupportAgent
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Shared by Asst</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/employer/settings")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/employer/settings") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <IoSettingsOutline
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Settings</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>

              <SidebarMenu.Nav
                className="mb-3 menu_body_menu"
                onClick={() => navigate("/employer/message")}
              >
                <SidebarMenu.Nav.Link
                  className={`${
                    isActive("/employer/message") ? "active-link" : ""
                  }`}
                >
                  <SidebarMenu.Nav.Icon className="px-2">
                    <IoChatboxEllipsesOutline
                      style={{ height: "20px", width: "20px" }}
                      className="navIcon"
                    />
                  </SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>
                    Message
                    {messagesNo?.count > 0 && (
                      <div
                        style={{
                          backgroundColor: "#ff5c09",
                          height: "6px",
                          width: "6px",
                          marginTop: "-13px",
                          marginLeft: "110px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    )}
                  </SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
          </SidebarMenu.Collapse>
        </SidebarMenu>
      </div>
    </>
  );
}

export default SideMenu;
