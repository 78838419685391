import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import { getAllEvents } from "../../../../store/slices/clientprofile";
import Moment from "moment";
import Modal2 from "../../auth_screens/Modal2";
import Skeleton from "react-loading-skeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
function Events() {
  const dispatch = useDispatch();

  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef | Events";
  }, []);
  const [data, setData] = useState([]);
  const [pagData, setPageData] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [dataCount, setdataCount] = useState();
  const [sortVal, setSortVal] = useState("");
  const [filterVal, setFilterVal] = useState("");
  const itemsPerPage = 10;
  const pagesToShowInitially = 3;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
  };

  const handleApplyFilterAndSort = () => {
    let sortedArray = [...data];

    // Apply filter
    if (filterVal === "status-active") {
      sortedArray = sortedArray.filter((elem) => elem?.status === 1);
    } else if (filterVal === "status-disable") {
      sortedArray = sortedArray.filter((elem) => elem?.status === 2);
    } else if (filterVal === "status-expired") {
      sortedArray = sortedArray.filter((elem) => elem?.status === 0);
    }

    // Apply sort
    if (sortVal === "event-new") {
      sortedArray.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
    } else if (sortVal === "event-old") {
      sortedArray.sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));
    }

    setFilteredData(sortedArray);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSortVal(value);
    search.set("sortVal", value);
    setSearch(search, { replace: true });
    setCurrentPage(1);
    handleApplyFilterAndSort();
  };

  const handleChangefilter = (e) => {
    const value = e.target.value;
    setFilterVal(value);
    search.set("filterVal", value);
    setSearch(search, { replace: true });
    setCurrentPage(1);
    handleApplyFilterAndSort();
  };

  useEffect(() => {
    setTimeout(() => {
      setisLoading(true);
    }, 300);
    setisLoading(false);
    handleApplyFilterAndSort();
  }, [filterVal, sortVal]);

  useEffect(() => {
    const sortingVal = search.get("sortVal");
    if (sortingVal !== null) {
      setSortVal(sortingVal);
    }

    const queryPage = parseInt(search.get("page")) || 1;
    setCurrentPage(queryPage);
  }, [search]);

  const [item, setItem] = useState({
    limit: itemsPerPage,
    page: currentPage,
  });

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPageData(filteredData.slice(start, end));
  }, [filteredData, currentPage, itemsPerPage]);

  React.useEffect(() => {
    dispatch(getAllEvents())
      .unwrap()
      .then((data) => {
        setData(data?.user?.data);
        setdataCount(data?.user?.dataCount);
        setFilteredData(data?.user?.data);
        setPageData(data?.user?.data?.slice(0, itemsPerPage));
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  const ShowSkeleton = () => {
    return (
      <>
        {Array(5)
          .fill()
          .map((item, index) => {
            return (
              <div key={index} className="row-sm-1 row-lg-1 card-group mb-4">
                <div className="card">
                  <Skeleton height={60} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Modal2
        showModal={showModal}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <div>
        <div
          className="text-start my-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#ff5c09",
          }}
        >
          <Link to="/hin/client/dashboard" className="bcrumb">
            डैशबोर्ड
          </Link>
          &nbsp;&bull; इवेंट्स
        </div>

        {dataCount === 0 && (
          <Row className="card mt-4 p-5">
            <Col lg={12} xs={12} md={12} align="center">
              <h3>कोई इवेंट पोस्ट नहीं किया गया</h3>
            </Col>
          </Row>
        )}
        {dataCount !== 0 && (
          <Row>
            <Form>
              <Row>
                <Col lg={9} sm={12} xs={12} md={12}>
                  <Row className="d-flex justify-content-between mb-2">
                    <Col lg={10} sm={5} xs={5} md={6}>
                      <label
                        style={{
                          height: "40px",
                          lineHeight: "40px",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Filter by Status:
                      </label>

                      <Form.Group className="mb-1">
                        <Form.Control
                          as="select"
                          name="experience"
                          value={filterVal}
                          onChange={handleChangefilter}
                          style={{
                            width: "120px",
                            fontSize: "13px",
                          }}
                        >
                          <option value="All">All</option>
                          <option value="status-active">Active</option>
                          <option value="status-expired">Expired</option>
                          <option value="status-disable">Disabled</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={2} sm={7} xs={7}>
                      <label
                        style={{
                          height: "40px",
                          lineHeight: "40px",
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginLeft: "-20px",
                        }}
                      >
                        Sort by:
                      </label>

                      <Form.Group className="mb-1">
                        <Form.Control
                          as="select"
                          name="experience"
                          value={sortVal}
                          onChange={handleChange}
                          style={{
                            width: "190px",
                            fontSize: "13px",
                            marginLeft: "-20px",
                          }}
                        >
                          <option value="event-new">Date - Newer</option>
                          <option value="event-old">Date - Older</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <div className="mt-4 col-lg-10 col-md-12 col-sm-11 col-xs-11 card">
              {isLoading ? (
                <div className="table-responsive">
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th className="text-left ps-3">Event Type</th>
                        <th className="text-left">Date</th>
                        <th className="text-left">Location</th>
                        <th className="text-left">Requests</th>
                        <th className="text-center"> Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagData?.map((eventData) => (
                        <tr key={eventData._id}>
                          <td className="text-left ps-3">
                            <Link
                              to={`/hin/client/event-details/${eventData._id}`}
                              style={{ color: "#478BFF", fontWeight: "500" }}
                            >
                              {eventData.eventType}
                            </Link>
                          </td>
                          <td className="text-left">
                            {Moment.utc(eventData.eventDate).format(
                              "DD-MM-YYYY"
                            )}
                          </td>

                          <td className="text-left"> {eventData.location}</td>
                          <td className="text-center">
                            {eventData.totalInterestsReceived}
                          </td>

                          <td className="text-center">
                            {eventData?.status === 0 && (
                              <span
                                style={{
                                  color: "#FF0606",

                                  fontSize: "14px",
                                  padding: "5px 7px",
                                  borderRadius: "5px",
                                  width: "80px",
                                }}
                              >
                                Expired
                              </span>
                            )}
                            {eventData?.status === 1 && (
                              <span
                                style={{
                                  color: "#00AF77",
                                  fontSize: "14px",
                                  padding: "5px 7px",
                                  borderRadius: "5px",
                                  width: "80px",
                                }}
                              >
                                Active
                              </span>
                            )}
                            {eventData?.status === 2 && (
                              <span
                                style={{
                                  color: "#CF8100",

                                  fontSize: "14px",
                                  padding: "5px 7px",
                                  borderRadius: "5px",
                                  width: "80px",
                                }}
                              >
                                Disabled
                              </span>
                            )}
                          </td>

                          <td className="text-center">
                            {eventData?.status !== 0 && (
                              <Link
                                to={`/hin/client/edit-event/${eventData._id}`}
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#ff5c09",

                                  fontSize: "14px",
                                  padding: "5px 17px",
                                  borderRadius: "5px",
                                  width: "80px",
                                  border: "solid 1px #ff5c09",
                                }}
                              >
                                Edit
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {filteredData?.length / itemsPerPage > 1 && (
                    <div className="justify-content-right align-right">
                      <ReactPaginate
                        key={currentPage}
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        breakLinkClassName={"page-link"}
                        pageCount={Math.ceil(
                          filteredData?.length / itemsPerPage
                        )}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={pagesToShowInitially}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        disabledClassName="disabled"
                        initialPage={currentPage - 1}
                        renderLastPage={() => null}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="table-responsive">{DataSkeleton()}</div>
              )}
            </div>
          </Row>
        )}
      </div>
    </>
  );
}

export default Events;
