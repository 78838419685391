import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import verified from "../../../../Assets/Images/checked.png";
import { MdOutlineEdit } from "react-icons/md";
import { getclientProfile } from "../../../../store/slices/clientprofile";
import Moment from "moment";
import {
  ClientChangeEmail,
  ClientChangeMobile,
  ClientChangeWhatsapp,
  ClientCheckWhatsapp,
  ClientVerifyEmail,
  ClientVerifyMobileOtp,
  ClientVerifyWhatsapOtp,
} from "../../../../store/slices/auth";
import Modal2 from "../../auth_screens/Modal2";
import ProgressBar from "react-bootstrap/ProgressBar";
import client from "../../../../Assets/Images/client.png";
import SuccessModal from "../../auth_screens/SuccessModal";
import ShowprofileSkeleton from "../../auth_screens/profileskeleton";
import DataSkeleton from "../../auth_screens/dataskeleton";
import AvatarModel from "../../../../utils/helpers/ImageCropper/AvatarModal";
import { API_PATHS } from "../../../../utils/constants/api.constants";
import { EditemployerProfile } from "../../../../store/slices/employerprofile";
import ChangeWatsappmodal from "../../../../utils/helpers/ChangeWatsappModal";

function ClientProfile() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const handleCloseModal = () => setShowModal1(false);
  const [data, setData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newMobile, setNewMobile] = useState("");
  const [modalType, setModalType] = useState(""); // "email", "mobile", "whatsapp"
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [isWhatsAppSameAsMobile, setIsWhatsAppSameAsMobile] = useState(false);
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [imageData, setImageData] = useState("");
  const [watsappchangemodal, setWatsapChangeModal] = useState(false);
  const [watsappchangemodalmessage, setWatsapChangeModalmessage] = useState("");

  const handleWhatsAppCheckboxChange = (e) => {
    setIsWhatsAppSameAsMobile(e.target.checked);

    if (e.target.checked) {
      setInputValue(data.mobile);
      setInputError("");
    } else {
      setInputValue("");
    }
  };

  const isValidEmail = (email) => {
    // Regular expression for a basic email validation
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidMobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setInputError(""); // Clear any previous error

    // Validate based on modalType
    if (modalType === "email") {
      setInputValue(value);
      if (value.trim() === "" && !isValidEmail(value)) {
        setInputError("कृपया एक मान्य ईमेल दर्ज करें");
      } else {
        setInputError("");
      }
    } else if (modalType === "mobile") {
      value = value.replace(/\D/g, "");

      // Limit the length to 10 characters
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      setInputValue(value);
      if (!isValidMobile(value)) {
        setInputError("कृपया एक वैध मोबाइल नंबर दर्ज करें");
      }
    } else if (modalType === "whatsapp") {
      value = value.replace(/\D/g, "");
      // Limit the length to 10 characters
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      setInputValue(value);
      if (!isValidMobile(value)) {
        setInputError("कृपया एक वैध WhatsApp नंबर दर्ज करें");
      }
    } else if (modalType === "otp") {
      value = value.replace(/\D/g, "");

      if (value.length > 6) {
        value = value.slice(0, 6);
      }
      setInputValue(value);
      if (value.length < 6 || value.length > 6) {
        setInputError("ओटीपी 6 अंकों का होना चाहिए");
      }
    } else if (modalType === "whatsappotp") {
      value = value.replace(/\D/g, "");

      if (value.length > 4) {
        value = value.slice(0, 4);
      }
      setInputValue(value);
      if (value.length < 4 || value.length > 4) {
        setInputError("ओटीपी 4 अंकों का होना चाहिए");
      }
    } else {
      setInputError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Handle submission based on modalType
    if (modalType === "email") {
      setInputError("");
      if (!inputValue.trim()) {
        setInputError("अपना ईमेल दर्ज करें");
      } else if (!isValidEmail(inputValue.trim())) {
        setInputError("कृपया वैध ईमेल दर्ज़ करें");
      } else {
        setInputError("");
        let item = {
          email: inputValue,
        };
        dispatch(ClientChangeEmail(item))
          .unwrap()
          .then((data) => {
            setModalMessage(data?.user.message);
            setsuccessModal(true);
            getProfile();
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            setLoading(false);
            console.log(data);

            if (data?.user.status == 1) {
              handleCloseModal();
              setInputValue(data.user.data.email);
            }
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    } else if (modalType === "mobile") {
      setInputError("");
      if (!inputValue) {
        setInputError("अपना मोबाइल नंबर दर्ज करें");
      } else if (!isValidMobile(inputValue)) {
        setInputError("कृपया वैध मोबाइल नंबर दर्ज करें");
      } else {
        setInputError("");
        let item = {
          mobile: parseInt(inputValue),
        };
        dispatch(ClientChangeMobile(item))
          .unwrap()
          .then((data) => {
            handleCloseModal();
            setModalMessage(data?.user?.message);
            setsuccessModal(true);

            if (data?.user?.responseCode == 1) {
              setNewMobile(data?.user?.data?.newMobile);
              handleShowModal("otp");
              setsuccessModal(false);
              setInputValue("");
            }
            setLoading(false);
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    } else if (modalType === "whatsapp") {
      setInputError("");
      if (!inputValue) {
        setInputError("कृपया अपना WhatsApp नंबर दर्ज करें");
      } else if (!isValidMobile(inputValue)) {
        setInputError("कृपया वैध WhatsApp नंबर दर्ज करें");
      } else {
        setInputError("");
        let item = {
          whatsappNumber: parseInt(inputValue),
        };
        dispatch(ClientCheckWhatsapp(item))
          .unwrap()
          .then((data) => {
            setIsWhatsAppSameAsMobile(false);
            changeWatsapp();
          })
          .catch(({ message }) => {
            if (
              message ===
              "This whatsapp number is already linked with another account. Do you want to update it?"
            ) {
              setWatsapChangeModalmessage(message);
              setWatsapChangeModal(true);
              setShowModal1(false);
            } else if (
              message ===
              "This whatsapp number is currently linked with your account."
            ) {
              setInputError(
                "This number is currently linked with your account"
              );
            }
          });
      }
    } else if (modalType === "otp") {
      setInputError("");
      if (!inputValue) {
        setInputError("कृपया ओटीपी दर्ज करें");
      } else if (inputValue.length < 6 || inputValue.length > 6) {
        setInputError("ओटीपी 6 अंकों का होना चाहिए");
      } else {
        setInputError("");
        let item = {
          otp: inputValue,
          mobile: parseInt(newMobile),
        };
        dispatch(ClientVerifyMobileOtp(item))
          .unwrap()
          .then((data) => {
            handleCloseModal();
            setModalMessage(data?.user.message);
            setsuccessModal(true);

            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            setLoading(false);
            getProfile();
            if (data?.user.status == 1) {
              handleCloseModal();
              setInputValue(data.user.data.mobile);
            }
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    } else if (modalType === "whatsappotp") {
      setInputError("");
      if (!inputValue) {
        setInputError("कृपया ओटीपी दर्ज करें");
      } else if (inputValue.length < 4 || inputValue.length > 4) {
        setInputError("ओटीपी 4 अंकों का होना चाहिए");
      } else {
        setInputError("");
        let item = {
          otp: inputValue,
          whatsappNumber: parseInt(newMobile),
        };
        dispatch(ClientVerifyWhatsapOtp(item))
          .unwrap()
          .then((data) => {
            handleCloseModal();
            setModalMessage(data?.user.message);
            setsuccessModal(true);
            setLoading(false);
            getProfile();
            setTimeout(() => {
              setsuccessModal(false);
            }, 3000);
            if (data?.user.status == 1) {
              handleCloseModal();
              setInputValue(data.user.data.whatsappNumber);
            }
          })
          .catch(({ message }) => {
            setInputError(message);
          });
      }
    }
  };

  const changeWatsapp = () => {
    let item = {
      whatsappNumber: parseInt(inputValue),
    };
    dispatch(ClientChangeWhatsapp(item))
      .unwrap()
      .then((data) => {
        handleCloseModal();
        setModalMessage(data?.user.message);
        setsuccessModal(true);
        setLoading(false);
        if (data?.user?.responseCode == 1) {
          setNewMobile(data?.user?.data?.whatsappNumber);
          handleShowModal("whatsappotp");
          setInputValue("");
          setsuccessModal(false);
        }
      })
      .catch(({ message }) => {
        setInputError(message);
      });
  };

  const handleShowModal = (type) => {
    setModalType(type);
    if (type !== "whatsapp") {
      setIsWhatsAppSameAsMobile(false);
    }
    setInputError("");
    setShowModal1(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    handleCloseModal();
    setInputError("");
  };

  const verifyemail = () => {
    dispatch(ClientVerifyEmail())
      .unwrap()
      .then((data) => {
        setModalMessage(data?.user.message);
        setsuccessModal(true);
        getProfile();
        setTimeout(() => {
          setsuccessModal(false);
        }, 3000);
        setLoading(false);
        handleCloseModal();
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  };

  React.useEffect(() => {
    getProfile();
  }, [dispatch]);

  const getProfile = () => {
    dispatch(getclientProfile())
      .unwrap()
      .then((data) => {
        setData(data?.user.data);
        setLanguages(data?.user.data?.languages);
        setLoading(false);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  useEffect(() => {
    document.title = "CookandChef | Profile";
  }, []);

  async function handleSave(requestOptions) {
    let response = await fetch(API_PATHS.uploadFileClient, requestOptions);
    let data2 = await response.json();
    const item = {
      dp: data2.data.location,
    };

    if (data2.data.location) {
      dispatch(EditemployerProfile(item))
        .unwrap()
        .then((data1) => {
          setModalMessage("Profile Photo updated succesfully");

          setsuccessModal(true);
          getProfile();

          setTimeout(() => {
            setsuccessModal(false);
          }, 3000);
        })
        .catch(({ message }) => {
          setModalMessage(message);
          setShowModal(true);
        });
    }
  }

  useEffect(() => {
    if (modalType === "mobile") {
      setInputValue(data?.mobile || "");
    } else if (modalType === "email") {
      setInputValue(data?.email || "");
    } else if (modalType === "whatsapp") {
      setInputValue(data?.whatsappNumber || "");
    }
  }, [modalType]);

  return (
    <>
      <div>
        <ChangeWatsappmodal
          showModal={watsappchangemodal}
          setShowModal={setWatsapChangeModal}
          modalMessage={watsappchangemodalmessage}
          func={changeWatsapp}
        />
        <Modal2
          showModal={showModal}
          modalMessage={modalMessage}
          setShowModal={setShowModal}
        />
        <AvatarModel
          showModal1={avatarModalOpen}
          image={imageData}
          setShowModal1={setAvatarModalOpen}
          handleSave={handleSave}
          setData={setData}
          data={data}
        />
        <SuccessModal
          showModal={successModal}
          modalMessage={modalMessage}
          setShowModal={setsuccessModal}
        />
      </div>
      <Modal
        show={showModal1}
        onHide={handleCloseModal}
        style={{ top: "20%", left: "5%" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header align="center">
          <Modal.Title align="center" style={{ fontSize: "20px" }}>
            {modalType === "email" && "ई-मेल"}
            {modalType === "mobile" && "मोबाइल नंबर"}
            {modalType === "whatsapp" && "व्हाट्सएप नंबर"}
            {modalType === "otp" && "अपना ओटीपी दर्ज करें"}
            {modalType === "whatsappotp" && "अपना ओटीपी दर्ज करें"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body align="left">
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Row>
              <Col lg={12} className="p-1 mb-3">
                {modalType === "whatsapp" && (
                  <Form.Group controlId="whatsappCheckbox" className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Same as मोबाइल नंबर"
                      onChange={handleWhatsAppCheckboxChange}
                      checked={isWhatsAppSameAsMobile}
                    />
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Control
                    name="inputValue"
                    value={inputValue}
                    onChange={handleChange}
                    isInvalid={!!inputError}
                    placeholder={
                      modalType === "email"
                        ? "अपना ईमेल दर्ज करें"
                        : modalType === "mobile"
                        ? "अपना मोबाइल नंबर दर्ज करें"
                        : modalType === "whatsapp"
                        ? "Please enter WhatsApp number"
                        : "कृपया ओटीपी दर्ज करें"
                    }
                    className="input1"
                    type={
                      modalType === "email"
                        ? "text"
                        : modalType === "mobile"
                        ? "text"
                        : "text"
                    }
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {inputError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <button
              className="modalcancelBtn m-2"
              style={{ width: "100px", cursor: "pointer" }}
              onClick={closeModal}
            >
              रद्द कीजिये
            </button>
            <button
              className="modalsaveBtn m-2"
              style={{ width: "100px", cursor: "pointer" }}
              type="submit"
            >
              जमा करना
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      <div
        className="text-start my-4"
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#ff5c09",
        }}
      >
        <Link to="/hin/client/dashboard" className="bcrumb">
          डैशबोर्ड
        </Link>
        &nbsp;&bull; प्रोफ़ाइल
      </div>
      {loading ? (
        <Row>{ShowprofileSkeleton()}</Row>
      ) : (
        <Row className="mt-2">
          <Col lg={12} md={12} xs={12} className="card">
            <Row>
              <Col lg={2} sm={3} md={3} xs={3} className="pb-3">
                {data?.dp ? (
                  <img
                    className="mt-2"
                    src={data.dp}
                    alt="user profile"
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "10%",
                    }}
                  />
                ) : (
                  <img
                    className="mt-2"
                    src={client}
                    alt="user profile"
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "10%",
                    }}
                  />
                )}
                <div style={{ marginTop: "-20px" }}>
                  <label for="fileInput" className="custom-buttonProfile">
                    <MdOutlineEdit />
                  </label>
                  <Form.Control
                    type="file"
                    name="profiledp"
                    id="fileInput"
                    width="20%"
                    accept="image/*"
                    onChange={(e) => handleChangeImage(e)}
                    className="input1"
                    placeholder="कृपया प्रोफ़ाइल फ़ोटो चुनें"
                  />
                </div>
              </Col>
              <Col
                lg={6}
                sm={8}
                xs={8}
                md={7}
                className="justify-content-left align-items-left ps-5"
              >
                <h5 className="mt-3">{data?.fullName}</h5>
                <Row className="mt-2">
                  <Col xs={12} lg={7} md={7}>
                    <span className="mb-2" style={{ fontSize: "12px" }}>
                      प्रोफ़ाइल पूर्णता {data?.profilePercent} %
                    </span>
                    <ProgressBar now={data?.profilePercent} />
                  </Col>
                </Row>
              </Col>
              <Col xs={11} md={2} lg={4} align="right" className="mt-2 mb-2">
                <Link
                  to="/hin/client/edit-profile"
                  style={{
                    color: "white",
                  }}
                >
                  <button className="editBtn">
                    <MdOutlineEdit /> संपादन करना
                  </button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {loading ? (
        <Row className="mt-4 d-flex justify-content-center card p-3">
          {DataSkeleton()}
        </Row>
      ) : (
        <Row className="mt-4 d-flex justify-content-center card p-3">
          <Col lg={12} xs={12} md={12} className="mb-4">
            <h5 className="text-left heading orange">सामान्य विवरण</h5>
            <Row className="gx-2">
              <Col lg={6} sm={12} md={6}>
                <p className="text-heading">जन्म की तारीख</p>
                {data?.dob && (
                  <p className="text-details">
                    {Moment.utc(data?.dob).format("DD-MM-YYYY")}
                  </p>
                )}
              </Col>
              <Col lg={6} md={6} sm={12}>
                <p className="text-heading">लिंग</p>
                {data?.gender === 1 && <p className="text-details">पुरुष</p>}
                {data?.gender === 2 && <p className="text-details">महिला</p>}
                {data?.gender === 3 && <p className="text-details">अन्य</p>}
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2">
                <p className="text-heading">पेशा</p>
                {data?.occupation && (
                  <p className="text-details">{data?.occupation}</p>
                )}
              </Col>
              <Col lg={12} sm={12} md={6} className="mt-2">
                <p className="text-heading">ज्ञात भाषाएँ</p>
                <Row style={{ marginTop: "-15px", marginLeft: "0px" }}>
                  {languages?.map((partyplanVal, index) => (
                    <Col
                      xs={5}
                      sm={5}
                      md={3}
                      lg={2}
                      xl={2}
                      className="me-2"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "10px",
                        color: "black",
                        padding: "4px 8px",
                        margin: "8px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                        marginRight: "4px",
                        border: "solid 1px #ff5c09",
                        width: "auto",
                      }}
                    >
                      {partyplanVal}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            <h5 className="text-left heading mt-3 orange">कांटेक्ट डिटेल्स</h5>
            <Row className="gx-2">
              <Col lg={6} md={6} sm={12} className="mt-2">
                <p className="text-heading">
                  मोबाइल नंबर
                  <span
                    style={{
                      color: "#3352D0",

                      fontSize: "12px",
                      fontWeight: "500",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleShowModal("mobile")}
                  >
                    <u>परिवर्तन</u>
                  </span>
                </p>
                <p className="text-details">{data?.mobile}</p>
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2">
                <div className="d-flex">
                  <p className="text-heading">
                    ईमेल &nbsp;
                    {data?.emailVerified === 1 && (
                      <img
                        src={verified}
                        style={{ height: "15px", width: "15px" }}
                        alt=""
                      />
                    )}
                    {data?.emailVerified === 0 && (
                      <>
                        <span
                          style={{
                            color: "#ff5c09",
                            fontStyle: "italic",
                            fontSize: "12px",
                            cursor: "pointer",
                            fontWeight: "500",
                          }}
                          onClick={() => verifyemail()}
                        >
                          वेरीफाई
                        </span>
                      </>
                    )}
                    <span
                      style={{
                        color: "#3352D0",

                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => handleShowModal("email")}
                    >
                      <u>परिवर्तन</u>
                    </span>
                  </p>
                </div>
                {data?.email && <p className="text-details">{data?.email}</p>}
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2">
                <p className="text-heading">
                  WhatsApp नंबर
                  <span
                    style={{
                      color: "#3352D0",

                      fontSize: "12px",
                      fontWeight: "500",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleShowModal("whatsapp")}
                  >
                    {data?.whatsappNumber && <u>परिवर्तन</u>}
                    {!data?.whatsappNumber && <u>जोड़ें</u>}
                  </span>
                </p>
                {data?.whatsappNumber && (
                  <p className="text-details">{data?.whatsappNumber}</p>
                )}
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2" />
              <Col lg={6} sm={12} md={6} className="mt-2">
                <p className="text-heading">पता पंक्ति 1</p>
                {data?.addressLine1 && (
                  <p className="text-details">{data?.addressLine1} </p>
                )}
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2">
                <p className="text-heading">पता पंक्ति 2</p>
                <p className="text-details">{data?.addressLine2}</p>
              </Col>
              <Col lg={6} md={6} sm={12} className="mt-2">
                <p className="text-heading">राज्य</p>
                {data?.provinceName && (
                  <p className="text-details">{data?.provinceName}</p>
                )}
              </Col>
              <Col lg={6} md={6} sm={12} className="mt-2">
                <p className="text-heading">शहर</p>
                <p className="text-details">{data?.cityName}</p>
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2">
                <p className="text-heading">क्षेत्र</p>
                {data?.area && <p className="text-details">{data?.area}</p>}
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2">
                <p className="text-heading">पिन कोड</p>
                <p className="text-details">{data?.pincode}</p>
              </Col>
              <Col lg={6} sm={12} md={6} className="mt-2">
                <p className="text-heading">लैंडमार्क </p>
                <p className="text-details">{data?.landmark}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ClientProfile;
